import WidgetsByMembersIds from "../enums/api/WidgetsByMembersId";
import WidgetNameEnum from "../enums/api/WidgetName";

export default function getWidget(result, key = "allMembers") {
    if (!result || !result[key]?.length) {
        return null;
    }

    const defaultWidget = {
        id: null,
        member: ["EBA6DC91-36D2-ED11-9F73-6045BD9258EA"],
        result: {
            schema: {
                type: "object",
                properties: {
                    last_member: {
                        type: "integer",
                        default: 1
                    }
                }
            }
        }
    }

    const resultMembersId = result[key].map(member => member.id);

    const desiredWidget = WidgetsByMembersIds.widget.find((widget) => {
        return widget.member.every(num => resultMembersId.includes(num))
    })

    return desiredWidget || defaultWidget
}

/**
 *service
 */
import axios from 'axios'
/**
 *service
 */
import StorageService from "../storage.service";
/**
 * const
 */
import CorIdentifyService from "./components/corIdentify.service";
import AzureService from "./components/azure.service";
import Auth from "../../utils/Auth";
import AuthMethodEnum from "../../enums/AuthMethod";



const getAuthModuleService = () => {
    const currentAuthMethod = Auth.getAuthMethod()

    if(currentAuthMethod === AuthMethodEnum.corIdentity){
        return CorIdentifyService
    }

    if(currentAuthMethod  === AuthMethodEnum.azure){
        return AzureService
    }

    return null
}

export default getAuthModuleService()

/**
 * external libs
 */
import React, {useContext, useEffect} from 'react';
import {Route} from 'react-router-dom';
import {useMsal} from "@azure/msal-react";
/**
 * context
 */
import {AuthContext} from "../../../pages/context/auth.context";
import AuthModuleService from "../../../services/authModule/authModule.service";


export default function AuthRoute(props) {
    const {user} = useContext(AuthContext)

    useEffect(() => {
        if(!user){
            AuthModuleService.redirectToLogin()
        }
    }, [])

    return user ? <Route {...props} /> : null
}

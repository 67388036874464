export default function checkPermission(userRoles, includeOne, includeAll){
    const roles = userRoles.flatMap( roles => roles);
    const rolesId = roles.map(({id}) => id);

    if(includeOne && includeOne.length){
        return includeOne.some( roleId => rolesId.includes(roleId))
    }

    if(includeAll && includeAll.length){
        return includeAll.every((roleId) => rolesId.includes(roleId))
    }

    return true
}

import {DatePicker} from "antd";
import React from "react";
import styles from "../../Widget/components/WidgetDate/components/Editable/index.module.scss";

export function DatePickerForm({ ...props }){
    return (
        <div className={styles.wrapper}>
            <DatePicker {...props} className={styles.wrapper__date}/>
        </div>
    )
}

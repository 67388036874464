/**
 * external libs
 */
import React, {useEffect, useState} from 'react'
import {FileSearchOutlined, ReloadOutlined} from '@ant-design/icons';
/**
 * components
 */
import EditableImageWidget from "./components/Editable";
/**
 * enums
 */
import {useMemo} from "react";
import getWidget from "../../../../utils/getWidget";
import {VIEWER_EKG_PAGE_URI} from "../../../../constants/uri.constant";
import ResultModel from "../../../../model/medicalCard/result.model";

const FileIcon = ({extension, ...props}) => {

    if(!['doc', 'docx', 'jpeg', 'jpg', 'pdf', 'png', 'svg', 'svs'].includes(extension)){
        return (
            <div {...props}>
                <svg style={{width: 30}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.0377 9.73154L18.5762 3.27C18.4904 3.18431 18.3885 3.11636 18.2765 3.07003C18.1644 3.0237 18.0443 2.9999 17.9231 3H6.84615C6.35652 3 5.88695 3.19451 5.54073 3.54073C5.1945 3.88695 5 4.35652 5 4.84615V25.1538C5 25.6435 5.1945 26.1131 5.54073 26.4593C5.88695 26.8055 6.35652 27 6.84615 27H23.4615C23.9512 27 24.4207 26.8055 24.767 26.4593C25.1132 26.1131 25.3077 25.6435 25.3077 25.1538V10.3846C25.3078 10.2634 25.284 10.1433 25.2377 10.0312C25.1913 9.91916 25.1234 9.81733 25.0377 9.73154ZM18.8462 6.15115L22.1565 9.46154H18.8462V6.15115ZM23.4615 25.1538H6.84615V4.84615H17V10.3846C17 10.6294 17.0973 10.8642 17.2704 11.0373C17.4435 11.2104 17.6783 11.3077 17.9231 11.3077H23.4615V25.1538ZM19.7692 15.9231C19.7692 16.1679 19.672 16.4027 19.4989 16.5758C19.3258 16.7489 19.091 16.8462 18.8462 16.8462H11.4615C11.2167 16.8462 10.9819 16.7489 10.8088 16.5758C10.6357 16.4027 10.5385 16.1679 10.5385 15.9231C10.5385 15.6783 10.6357 15.4435 10.8088 15.2704C10.9819 15.0973 11.2167 15 11.4615 15H18.8462C19.091 15 19.3258 15.0973 19.4989 15.2704C19.672 15.4435 19.7692 15.6783 19.7692 15.9231ZM19.7692 19.6154C19.7692 19.8602 19.672 20.095 19.4989 20.2681C19.3258 20.4412 19.091 20.5385 18.8462 20.5385H11.4615C11.2167 20.5385 10.9819 20.4412 10.8088 20.2681C10.6357 20.095 10.5385 19.8602 10.5385 19.6154C10.5385 19.3706 10.6357 19.1358 10.8088 18.9627C10.9819 18.7896 11.2167 18.6923 11.4615 18.6923H18.8462C19.091 18.6923 19.3258 18.7896 19.4989 18.9627C19.672 19.1358 19.7692 19.3706 19.7692 19.6154Z" fill="#323237"/>
                </svg>
            </div>
        )
    }

    return (
        <div {...props}>
            <img src={`/image/files/${extension}.svg`} alt="ext" style={{width: 30}}/>
        </div>
    )
}

const Wrapper = ({description, children}) => {
    const LinkWrapper = ({styles, children}) => {
        return (
            <div style={styles}>
                {children}
            </div>
        )
    }

    if (description) {
        return (
            <LinkWrapper styles={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                position: 'relative'
            }}>
                <div>{description}</div>
                {children}
            </LinkWrapper>
        )
    }

    return <LinkWrapper styles={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        position: 'relative'
    }}>{children}</LinkWrapper>
}

export default function WidgetFile({result, field, widget, extensions, editable, showDescription, onEdit}) {
    const [isReady, setIsReady] = useState(false)
    const [isThumbnail, setIsThumbnail] = useState(false)
    const [isFile, setIsFile] = useState(false)

    const findFiles = () => {
        const urlFile = getUrl()

        if(!urlFile){
            return  setIsReady(true)
        }

        fetch(urlFile, {
            method: "HEAD"
        })
            .then(res => {
                if (res.status !== 200) {
                    setIsReady(true)
                    return;
                }

                setIsFile(true)

                if (!widget || !widget.result?.schema?.properties[field]?.['x-path']?.thumbnail) {
                    setIsReady(true)
                    return;
                }

                fetch(getThumbnail(), {
                    method: "HEAD"
                })
                    .then(res => {
                        if (res.status === 200) {
                            setIsReady(true)
                            setIsThumbnail(true)
                            return
                        }

                        setIsReady(true)
                        setIsThumbnail(false)
                    })
                    .catch(() => setIsReady(true))
            })
            .catch(() => setIsReady(true))
    }

    const getUrl = () => {
        return result.measures?.[field]?.url || null
    }

    const getThumbnail = () => {
        const fileUrl = result.measures?.[field]?.url;
        const extensionFile = fileUrl.name.split('.')[fileUrl.name.split('.').length - 1]
        const [thumbnailKey] = Object.keys(widget.result.schema.properties[field]?.['x-path'].thumbnail)
        let thumbnailUrl = widget.result.schema.properties[field]?.['x-path'].thumbnail[thumbnailKey]
        thumbnailUrl = thumbnailUrl.replace('{result.id}', `${result.id.toUpperCase()}`)
        thumbnailUrl = thumbnailUrl.replace('{ext}', `${extensionFile}`)
        thumbnailUrl = thumbnailUrl.replace("{url.last}", fileUrl.split('/')[fileUrl.split('/').length - 1])
        thumbnailUrl = thumbnailUrl.replace("{url.last.noext}", fileUrl.split('/')[fileUrl.split('/').length - 1].split('.')[0])

        return thumbnailUrl
    }

    const openLinkToNewTab = (e) => {
        e.preventDefault();
        e.stopPropagation();


        window.open(showUrl());
    }

    const showUrl = () => {
        const ext = getUrl().split('.')[getUrl().split('.').length - 1]

        if(ext === "cor-ekg"){
            return `${VIEWER_EKG_PAGE_URI}?path=${getUrl()}`
        }

        return getUrl()
    }

    const autoGenerateUrl = () => {
        if(!widget){
            return null
        }

        const currentWidget  = widget.result.schema.properties[field];

        if(!currentWidget || result?.measures?.[field]?.url){
            return null
        }

        const {upload_url, auto_generate, extension} = currentWidget['x-path'];

        if(!auto_generate){
            return null
        }

        let currentUrl = upload_url;
        const [ext] = extension;
        currentUrl = currentUrl.replace("{result.id}", result.id.toUpperCase())
        currentUrl = currentUrl.replace("{token}", result.token || new ResultModel().token)
        currentUrl = currentUrl.replace("{ext}", ext)
        currentUrl = currentUrl.replace("{azure_blob_storage}", process.env.REACT_APP_AZURE_BLOB_STORAGE)

        onEdit({
            ...result,
            is_modified: true,
            measures: {
                ...result.measures,
                [field]: {
                    url: currentUrl,
                },
            }
        })
    }

    useEffect(() => {
        autoGenerateUrl()
    }, [])


    useEffect(() => {
        findFiles()
    }, [result.measures?.[field]?.url])


    if (!isReady) {
        return null
    }

    if (editable && widget && !widget.result.schema.properties[field].auto_generate) {
        return <EditableImageWidget widget={widget.result.schema.properties[field]}
                                    result={result}
                                    field={field}
                                    onEdit={onEdit}
                                    onBlur={() => {
                                    }}/>
    }

    if (!isFile && getUrl()) {
        return (
            <Wrapper description={showDescription && widget && widget.result.schema.properties[field].description}>
                <a href={showUrl()} onClick={openLinkToNewTab} target={"_blank"}>
                    <img className ="widgetImg"
                         style={{cursor: "pointer", width: 30, height: 30}}
                         src={`/image/icon/question.svg`}
                         alt=" 1 "/>
                </a>
            </Wrapper>
        )
    }

    if (!isFile) {
        return null
    }

    if (widget && widget.result?.schema?.properties[field]?.['x-path']?.thumbnail && isThumbnail) {
        return (
            <Wrapper description={showDescription && widget && widget.result.schema.properties[field].description}>
                <img className="widgetImg"
                     style={{cursor: "pointer"}}
                     src={`${getThumbnail()}`}
                     alt=" 2 "/>
            </Wrapper>
        )
    }

    if (isFile) {
        let [ext] = extensions;

        return (
            <Wrapper description={showDescription && widget && widget.result.schema.properties[field].description}>
                {getUrl() ? (
                    <a href={showUrl()} target={"_blank"}>
                        <FileIcon style={{fontSize: 40, marginTop: 5, cursor: "pointer"}}
                                  extension={ext}/>
                    </a>
                ) : (
                    <FileIcon style={{fontSize: 40, marginTop: 5, cursor: "pointer"}}
                              extension={ext}/>
                )}
            </Wrapper>
        )
    }

    return null
}

/**
 * external libs
 */
import React from 'react'
import {Link} from "react-router-dom";
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * const
 */
import {HOME_PAGE_URI} from "../../../../constants/uri.constant";

export default function SideBarTop({ setOpenSideBar }){
    return (
        <div className={styles.topSideBar}>
            <div className={styles.topSideBar__btn} onClick={() => setOpenSideBar(openSideBar => !openSideBar)}>
                <span className={styles.topSideBar__btn_line}/>
                <span className={styles.topSideBar__btn_line}/>
                <span className={styles.topSideBar__btn_line}/>
            </div>
            <Link to={HOME_PAGE_URI}>
                <img src="/image/logo.svg" alt="logo" className={styles.topSideBar__logo}/>
            </Link>
        </div>
    )
}

/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {Input} from "antd";
import PhoneInput from 'react-phone-input-2'
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * enums
 */
import ResultKeysEnum from "../../../../../../enums/api/ResultKeys";
import FieldItem from "../../../../../Form/components/FieldItem";
import detectLanguage from "../../../../../../utils/detectLanguage";


export default function EditablePhoneWidget({isFocused = true, resultValue, validationErrorMessage, widget, onEdit, field, onBlur, result}){
    const [resultData, setResultData] = useState(result)
    const inputRef = useRef(null)
    const [countryCode, setCountryCode] = useState(''); 

    useEffect(() => {
        if (!resultValue) {
            fetch('https://ipapi.co/json/')
                .then(response => response.json())
                .then(data => {
                    if (data && data.country_code) {
                        setCountryCode(data.country_code.toLowerCase());
                    }
                })
                .catch(error => console.error('Error fetching country code:', error));
        }

        setResultData(result);
    }, [result]);

    const onBlurHandler = () => {
        if(onEdit){
            onEdit(resultData)
        }

        if(onBlur){
            onBlur(resultData)
        }
    }

    const onChangeHandler = (number) => {
        const value = `+${number.trim()}`;

        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: value,
        } : value

        setResultData({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
    }

    useEffect(() => {
        setResultData(result)
    }, [result])


    return (
        <FieldItem label={widget.description || ""}  required={!widget.type.includes("null")} stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                <PhoneInput
                    value={resultValue || ""}
                    inputClass={styles.wrapper__input}
                    buttonClass={styles.wrapper__input}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    country={countryCode}
                />
            </div>
        </FieldItem>
    )
}

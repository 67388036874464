import LevelModel from "./level.model";
import ContentItemModel from "./contentItem.model";

export default class CatalogModel {
    constructor(data = {}) {
        this.id = data.id;
        this.row_count = data.row_count;
        this.name = data.name;
        this.level = data.level ? data.level.map( level => new LevelModel(level)) : [];
        this.content = data.content ? data.content.map( contents => {
            return contents.map( content => new ContentItemModel(content))
        }) : [];
    }
}

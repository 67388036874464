import DimensionModel from "../../dimension.model";

export default class ColumnModel {
    constructor(data = {}) {
      this.column = data.column || null;
      this.is_required = data.is_required || false;
      this.is_multiple = data.is_multiple || false;
      this.is_restricted = data.is_restricted || false;
      this.dimension = data.dimension ? new DimensionModel(data.dimension) : null;
    }
  }
export default function ruTranslate() {
    return {
        "Calendar": "Календарь",
        "Notifications": "Уведомления",
        "Statistics": "Статистика",
        "Search": "Поиск",
        "Create record": "Создать запись",
        "Settings": "Настройки",
        "Security": "Безопасность",
        "Folders": "Папки",
        "Catalogs": "Каталоги",
        "Widgets": "Виджеты",
        "Language": "Язык",
        "Label":"Ярлык",
        "Relation": "Отношения",
        "Validity period": "Срок действия",
        "Share link": "Поделиться ссылкой",
        "Forever": "Навсегда",
        "Year": "Год",
        "Month": "Месяц",
        "Copied": "Скопировано",
        "Week": "Неделя",
        "Can view": "Может просматривать",
        "Can edit": "Можно редактировать",
        "Can reshare": "Можно поделиться повторно",
        "Scan QR": "Сканировать QR-код",
        "Share this medical card": "Поделитесь этой медицинской картой",
        "Share":"Поделиться",
        "Features and tips": "Функции и советы",
        "Q&A": "Вопросы и ответы",

        "Histopathological report": "Гистопатологический отчет",
        "Date of collection": "Дата забора",
        "Date of reception": "Дата приема",
        "Answer date": "Дата ответа",
        "Medical card": "Медицинская карта",
        "H/B": "Д/Р",
        "Age": "Возраст",
        "Patient": "Пациент",
        "Sex": "Пол",
        "Contacts": "Контакты",
        "Medical institution": "Медицинское учреждение",
        "Treating doctor": "Лечащий врач",
        "Clinical diagnosis": "Клинический диагноз",
        "Clinical data": "Клинические данные",
        "Macro": "Макро",
        "Material type": "Тип материала",
        "Fixation": "Фиксация",
        "Number of blocks": "Количество блоков",
        "Decalcification": "Декальцинация",
        "Macro archive": "Макро",
        "Dyeing": "Окрас",
        "Micro": "Микро",
        "Pathological diagnosis": "Патологический диагноз",
        "Comment": "Комментарий",
        "Pathologist": "Врач-патологоанатом",

        "User agreement": "Пользовательское соглашение",
        "Update the app": "Обновить приложение",
        "Date": "Дата",
        "Value": "Значение",
        "Time": "Время",
        "All notifications": "Все уведомления",
        "Default": "По умолчанию",
        "Diagnosis": "Диагноз",
        "Date of visits": "Дата посещений",
        "Status": "Состояние",
        "Full name": "Полное имя",
        "Gender and age": "Пол и возраст",
        "Add account": "Добавить аккаунт",
        "Log out": "Выйти",
        "Action": "Действия",
        "y.o.": "лет",
        "Create result": "Создать результат",
        "Create New Folder": "Создать новую папку",
        "Upload Catalog": "Загрузить каталог",
        "Create child folder": "Создать дочернюю папку",
        "Copy folder id": "Скопировать id папки",
        "Add new result": "Добавить новый результат",
        "Insert into record": "Вставить в запись",
        "Insert": "Вставить",
        "Add": "Додати",
        "Go to record": "Перейти на результат",
        "Edit": "Редактировать",
        "Edit Catalog": "Редактировать каталог",
        "Icon": "Иконка",
        "Name": "Имя",
        "Translation Warnings:": "Предупреждения о переводе:",
        "Copy to clipboard": "Копировать в буфер обмена",
        "Ordinal": "Порядковый номер",
        "Catalog": "Каталог",
        "Permit": "Доступ",
        "Included": "Включенные",
        "Excluded": "Исключенные",
        "Permitted": "Доступные",
        "Enable": "Включить",
        "Enabled": "Включено",
        "Dimensions": "Размеры",
        "Included members": "Включенные элементы",
        "Catalog Name": "Название каталога",
        "Dimensions for": "Размеры для",
        "Explore": "Исследовать",
        "Is required": "Требуется",
        "Is restricted": "Ограничен",
        "Edit record": "Редактировать запись",
        "Go to widget": "Перейти к виджету",
        "Is multiple": "Множественный",
        "Dimension": "Размер",
        "Count": "Счет",
        "Save": "Сохранить",
        "Cancel": "Отменить",
        "Excluded members": "Исключенные элементы",
        "Row count": "Количество строк",
        "Explore JSON": "Исследовать JSON",
        "Explore CSV": "Исследовать CSV",
        "Download catalog": "Скачать каталог",
        "Member": "Элемент",
        "Result": "Результат",
        "Actions": "Действия",
        "Duplicate": "Дублировать",
        "Copy Id": "Скопировать Id",
        "Delete": "Удалить",
        "Create New Widget": "Создать новый виджет",
        "General": "Общее",
        "Select data": "Выбрать данные",
        "Select date": "Выбрать дату",
        "Page not found": "Страница не найдена",
        "Include": "Включить",
        "Incarnation": "Инкарнация",
        "Delete all data": "Удалить все данные",
        "Reset Password": "Сбросить пароль",
        "Yes, I\'m sure I want to delete my account and all associated data": "Да, я уверен, что хочу удалить свою учетную запись и все связанные с ней данные.",
        "Roles": "Роли",
        "Create Permission": "Создать разрешение",
        "Permissions": "Разрешения",
        "Parent Folder": "Родительская папка",
        "Edit Folder": "Редактировать папку",
        "Edit Widget": "Редактировать виджеты",
        "Exclude": "Исключить",
        "Members": "Элементы",
        "Translation": "Перевод",
        "Will come soon": "Скоро будет",
        "Are you sure you want to delete this catalog?": "Вы уверены, что хотите удалить этот каталог?",
        "Add glass QR code": "Добавить QR-код для стекла",
        "Create glass QR code": "Создать QR-код для стекла",
        "Yes": "Да",
        "No": "Нет",
        "Data saved successfully!": "Данные успешно сохранены!",
        "Permitted members": "Разрешенные элементы",
        "Failed to save data.": "Не удалось сохранить данные.",
        "Translations": "Переводы",
        "Are you sure you want to delete this result?": "Вы уверены, что хотите удалить этот результат?",
        "Are you sure you want to delete this record?": "Вы уверены, что хотите удалить эту запись?",
        "Are you sure you want to delete this medical card?": "Вы уверены, что хотите удалить эту медицинскую карту?",
        "You successfully removed medical card": "Вы успешно удалили медицинскую карту",
        "The medical record you are trying to delete does not exist!": "Медицинская запись, которую вы пытаетесь удалить, не существует!",
        "There are no records in this folder yet.": "В этой папке пока нет записей.",
        "Fill in required fields!": "Заполните обязательные поля!",
        "Policy Pages": "Страницы политики",
        "User Agreement": "Пользовательское соглашение",
        "Personal info": "Обработка персональных данных",
        "You must fix the validation error": "Вы должны исправить валидационную ошибку",
        "ValidationMessage.minimum": "Значение должно быть больше или равно{{ error.argument }}",
        "ValidationMessage.maximum": "Значение должно быть меньше или равно {{ error.argument }}",
        "ValidationMessage.exclusiveMinimum": "Значение должно быть больше {{ error.argument }}",
        "ValidationMessage.exclusiveMaximum": "Значение должно быть меньше {{ error.argument }}",
        "ValidationMessage.multipleOf": "Значение должно быть кратно {{ error.argument }}",
        "ValidationMessage.minLength": "Значение должно быть больше или равно {{ error.argument }}",
        "ValidationMessage.maxLength": "Значение должно быть меньше или равно {{ error.argument }}",
        "ValidationMessage.pattern": "Недопустимое значение",
        "ValidationMessage.type": "Поле обязательно к заполнению",
        "ValidationMessage.format": 'Не соответствует формату "{{ error.argument }}"',
        "The result must have at least one member": "В результате должен быть хотя бы один мембер",
    };
}

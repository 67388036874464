/**
 *service
 */
import axios from 'axios'
/**
 *service
 */
import StorageService from "./storage.service";
/**
 * const
 */
import {
    API_MEDICAL_DATA_CREATE_URL,
    API_MEDICAL_DATA_DELETE_URL,
    API_MEDICAL_DATA_LIST, API_MEDICAL_GET_NEW_CARD
} from "../constants/api.constant";
/**
 * models
 */
import MedicalCardModel from "../model/medicalCard/medicalCard.model";
/**
 * utils
 */
import {QueryString} from "../utils/Querystring";
import AuthService from "./auth.service";

export default class MedicalCardService {
    static async list( params = {} ) {
        return await axios.get(API_MEDICAL_DATA_LIST, {
            params,
            paramsSerializer: params => {
                return QueryString.stringifyToBackand(params);
            },
        })
            .then(medicalCards => medicalCards.map( medicalCard => new MedicalCardModel(medicalCard)))
            .catch(e => Promise.reject(e))
    }

    static async getNew( params = {} ) {
        return await axios.get(API_MEDICAL_GET_NEW_CARD, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .then(medicalCards => medicalCards.map( medicalCard => new MedicalCardModel(medicalCard)))
            .catch(e => Promise.reject(e))
    }

    static async create( data = {} ) {
        return await axios.post(API_MEDICAL_DATA_CREATE_URL,  data)
            .catch(e => Promise.reject(e))
    }

    static async delete( medicalCardId ) {
        return await axios.delete(API_MEDICAL_DATA_DELETE_URL,{params: {id: medicalCardId}})
            .catch(e => Promise.reject(e))
    }
}

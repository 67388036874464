/**
 *service
 */
import axios from 'axios'
/**
 *service
 */
import StorageService from "../../storage.service";
/**
 * const
 */
import {jwtDecode} from "jwt-decode";
import {QueryString} from "../../../utils/Querystring";
import {authConfig, authDomain} from "../../../authConfig/corIdentity";

export default class CorIdentifyService {
    static getAuth() {
        const token = StorageService.accessToken;

        if(token){
            return;
        }

        const {access_token, refresh_token} = QueryString.parseQueryString(window.location.search);

        if(access_token && refresh_token){
            StorageService.accessToken = access_token
            StorageService.refreshToken = refresh_token
        }
    }

    static async getAccessToken() {
        const token = StorageService.accessToken;
        const isValid = this.validToken(token)

        if(isValid){
            return token
        }

        return await this.getRefreshToken()
    }

    static async redirectToResetPassword(){
        return window.location.href = authConfig.resetPassword
    }

    static async redirectToLogin() {
        StorageService.removeAccessToken()
        StorageService.removeRefreshToken()

        return window.location.href = `${authConfig.signUpSignIn}?redirectUrl=${authConfig.redirectUri}`
    }

    static async getRefreshToken() {
        const token = StorageService.refreshToken;
        const isValid = this.validToken(token)

        if(isValid){
             const res  = await fetch(`${authDomain}/api/auth/refresh_token`, {
                 method: "GET",
                 headers: {
                     "Content-Type": "application/json",
                     "Authorization": 'Bearer ' + token,
                 },
             })
            const tokens = await res.json()
            const {access_token, refresh_token} = tokens;

            if(access_token && refresh_token){
                StorageService.accessToken = access_token
                StorageService.refreshToken = refresh_token
            }
        }


        return null
    }

    static isAuthenticate(){
        return !!StorageService.accessToken;
    }

    static validToken(token) {
        try {
            const {exp} = jwtDecode(token)

            if(!exp){
                return false
            }

            const expTime = exp * 1000;
            const dateNow = new Date().getTime();

            return dateNow < expTime;
        }
        catch (e){
            return false
        }
    }


    static logout() {
        StorageService.removeAccessToken()
        StorageService.removeRefreshToken()
        StorageService.removeActiveAuthMethod()

        window.location.href = `${authConfig.signUpSignIn}?redirectUrl=${authConfig.redirectUri}`
    }

    static getAuthMethod(){
        return "CorToken"
    }
}


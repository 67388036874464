/**
 * external libs
 */
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
/**
 * context
 */
import WidgetsByMembersIds from "../../enums/api/WidgetsByMembersId";
import {AuthContext} from "./auth.context";

export const WidgetContext = createContext({});

export function WidgetContextProvider({children}) {
    const {user} = useContext(AuthContext)
    const [isReady, setIsReady] = useState(false);

    const getWidget = useCallback(async () => {
        if(user){
            await WidgetsByMembersIds.getWidget()
        }

        setIsReady(true)
    }, []);

    useEffect(() => {
        getWidget()
    }, []);


    return isReady ? <WidgetContext.Provider value={{}}>{children}</WidgetContext.Provider> : null;
}

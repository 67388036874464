/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import SideBarItem from "./components/Item";

export default function SideBar({sideBarItems = [], activeFolder, disableFolders, openSideBar = true, firstActive = false}) {

    return sideBarItems.map((item, index) => {
        if(item.hide){
            return null
        }

        return <SideBarItem key={item.title} activeFolder={activeFolder} disableFolders={disableFolders} index={index} item={item} openSideBar={openSideBar} firstActive={firstActive}/>
    })
}

/**
 * external libs
 */
import React, {useState} from 'react'
import {useHistory} from "react-router-dom";
/**
 * styles
 */
import styles from "../Item/index.module.scss";
/**
 * services
 */
import MedicalCardService from "../../../../services/medicalCard.service";
/**
 * constants
 */
import {MEDICAL_CARDS_MAKE_PAGE_URI} from "../../../../constants/uri.constant";
import {useContext} from "react";
import {AlertContext} from "../../../../pages/context/alert.context";
import CatalogService from "../../../../services/catalog.service";
import AddNewCardMemberModal from "../../../Modal/AddNewCardMember";
import FolderIdEnum from "../../../../enums/api/FolderId";
import IncarnationEnum from "../../../../enums/api/Incarnation";
import matchTheSameMembers from "../../../../utils/matchTheSameMembers";

export default function AddMedCard({folderId, folder}) {
    const {setAlertError} = useContext(AlertContext)
    const history = useHistory()
    const [isReady, setIsReady] = useState(true);
    const [openSelectMember, setOpenSelectMember] = useState(false);

    const createMedCardFlow = async () => {
        setIsReady(false)

        try {
            const {rows, include} = await CatalogService.content({
                folder_id: folderId,
                catalog_id: folder.catalog.id,
                depth: 1,
            })




            if(!rows.length){
                const currentGlobalMembers = matchTheSameMembers(include.global_members)
                const currentLocalMembers = matchTheSameMembers(include.local_members)
                const allMembers = [...currentGlobalMembers, ...currentLocalMembers]
                const allFiltredMembers = [...new Set(allMembers.map(({id}) => id))].map(currentId => {
                    return allMembers.find(({id}) => id === currentId)
                })

                return await createNewMedCard({
                    member: allFiltredMembers.map(({id, dimension, columnOptions}) => ({
                        id: id,
                        incarnation: columnOptions?.dimension.incarnation[0].id || dimension.incarnation[0].id
                    }))
                })
            }

            setOpenSelectMember(true)
        }
        catch (error){
            setAlertError(error?.message)
        }
        finally {
            setIsReady(true)
        }
    }

    const createNewMedCard = async (params = {}) => {
        try {
            const emptyMember = {
                [IncarnationEnum.id.cardHolder]: [],
                [IncarnationEnum.id.card]: [],
            }

            setOpenSelectMember(false)

            return history.push({
                pathname: MEDICAL_CARDS_MAKE_PAGE_URI("new", FolderIdEnum.contacts),
                state: {
                    parentFolderId: folderId,
                    member: (params.member || []).reduce((members, member) => {
                        return {
                            ...members,
                            [member.incarnation]: [
                                ...(members[member.incarnation] || []),
                                member.id
                            ]
                        }
                    }, emptyMember),
                },
            })
        }
        catch (error){
            setAlertError(error?.message)
        }
    }

    if(openSelectMember){
        return <AddNewCardMemberModal catalogId={folder.catalog.id}
                                      onOk={(member) => createNewMedCard({member})}
                                      onClose={() => setOpenSelectMember(false)}
                                      folderId={folderId}/>
    }


    if (!isReady) {
        return (
            <svg className={`${styles.item__action}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width="150px"
                 height="150px" viewBox="0 0 128 128">
                <path fill="#323238"
                      d="M64.4 16a49 49 0 0 0-50 48 51 51 0 0 0 50 52.2 53 53 0 0 0 54-52c-.7-48-45-55.7-45-55.7s45.3 3.8 49 55.6c.8 32-24.8 59.5-58 60.2-33 .8-61.4-25.7-62-60C1.3 29.8 28.8.6 64.3 0c0 0 8.5 0 8.7 8.4 0 8-8.6 7.6-8.6 7.6z">
                    <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1800ms"
                                      repeatCount="indefinite"></animateTransform>
                </path>
            </svg>
        )
    }

    return (
        <svg className={`${styles.item__action} ${styles.item__action_add}`} onClick={createMedCardFlow} width="32" height="32" viewBox="0 0 32 32"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 16H24.25" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 7.75V24.25" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

import React from 'react'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // initialize the error state
        this.state = { hasError: false };
    }

    // // if an error happened, set the state to true
    // static getDerivedStateFromError(error) {
    //     return window.location.href = '/500.html'
    // }

    render() {
        return this.props.children;
    }
}

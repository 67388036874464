export default class AcceptedLanguageEnum {
  static get uk() {
    return "uk";
  }
  static get en() {
    return "en";
  }
  static get ru() {
    return "ru";
  }
  static get zh() {
    return "zh";
  }
  static get pl() {
    return "pl";
  }
  static get list(){
    return [AcceptedLanguageEnum.uk, AcceptedLanguageEnum.en, AcceptedLanguageEnum.zh, AcceptedLanguageEnum.ru , AcceptedLanguageEnum.pl]
  }
}

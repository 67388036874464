export default class FolderIdEnum {
  static get contacts() {
      return "2E8EC6DB-A9DA-ED11-9F73-6045BD9258EA";
  }
  static get myCards() {
      return "1FB89956-8BCD-4232-AFC3-7FB57B116C9F";
  }
  static get allRecords() {
      return "782C6459-373C-4ACA-A5D9-A5978377C9D0";
  }
  static get patientTableInfo() {
      return "2FEB07CC-364B-4AB2-9C9B-F7F9F405D903";
  }
  static get printQr() {
      return "D9A5E109-10E2-45EC-9740-F303A18DD8E6";
  }
  static get histopathology() {
      return "980B0A73-8BC2-4366-AB2B-44C102550EF1";
  }
}

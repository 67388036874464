/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
import {NavLink, useLocation} from "react-router-dom";
/**
 * styles
 */
import ChildrenItem from '../ChildrenItem'
/**
 * styles
 */
import styles from './index.module.scss'
import GetIcon from "../../../GetIcon";
import AddMedCard from "../AddMedCard";
import FolderIdEnum from '../../../../enums/api/FolderId'

const WrapperComponent = ({
                              children, link, click = () => {
    }, item, itemId, disableFolders, activeFolder, openSideBar, setOpenChildren
                          }) => {
    const active = useMemo(() => itemId && (activeFolder === itemId), [activeFolder, itemId]);
    const disabled = useMemo(() => disableFolders && !active, [active, disableFolders]);

    if (link) {
        return (
            <NavLink to={link}
                     onClick={() => click(item)}
                     className={isActive =>
                         `${styles.item} ${!openSideBar && styles.close} ${(active || isActive) && styles.active} ${disabled && styles.disabled}`
                     }>
                {children}
            </NavLink>
        )
    }

    return (
        <div className={`${styles.item} ${!openSideBar && styles.close} ${disabled && styles.disabled}`}
             style={{opacity: disableFolders ? .5 : 1}}
             onClick={() => {
                 if (!disableFolders) {
                     setOpenChildren(open => !open)
                     click(item)
                 }
             }}>
            {children}
        </div>
    )
}

export default function SideBarItem({item, index, activeFolder, openSideBar = true, disableFolders, firstActive}) {
    const location = useLocation()
    const [active, setActive] = useState((firstActive && index === 0) || !location.pathname.indexOf(item.link))
    const [openChildren, setOpenChildren] = useState(false)

    useEffect(() => {
        setActive((firstActive && index === 0) || !location.pathname.indexOf(item.link))
    }, [location.pathname])

    const generateLink = (link, hash) => {
        let generatedLink = link;

        if (!generatedLink) {
            return null
        }

        if (hash) {
            generatedLink += `#${hash}`
        }

        return generatedLink
    }

    return (
        <React.Fragment>
            <div className={styles.hover}>
                <WrapperComponent to={item.link} click={item.onClick} item={item} itemId={item.folderId}
                                  activeFolder={activeFolder} disableFolders={disableFolders}
                                  link={generateLink(item.link, item.hash)} openSideBar={openSideBar}
                                  active={active} setOpenChildren={setOpenChildren}>
                    <div className={styles.item__flex}>
                        <div className={styles.item__icon}>
                            <GetIcon {...item.iconProps}/>
                        </div>
                        <p className={styles.item__title}>{item.title}</p>
                    </div>
                </WrapperComponent>
                <div className={`${styles.item__flex} ${styles.item__actions}`}>
                    {item.addCard && (item.folderId !== FolderIdEnum.myCards) &&
                        <AddMedCard folder={item.folder} folderId={item.folderId}/>}
                    {item.children && (
                        <svg className={`${styles.item__action} ${openChildren && styles.open}`} width="24" height="24"
                             viewBox="0 0 24 24" fill="none"
                             onClick={() => setOpenChildren(open => !open)}
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 9L12 15L6 9" stroke="#323238" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    )}
                    {!item.children && item.action && item.action()}
                </div>
            </div>
            {openSideBar && openChildren && item.children &&
                <ChildrenItem items={item.children} activeFolder={activeFolder} disableFolders={disableFolders}
                              openSideBar={openSideBar}/>}
        </React.Fragment>
    )
}

/**
 * external libs
 */
import React, {useState} from 'react'
import {Modal} from 'antd';
/**
 * components
 */
import CreateNewCardMember from "../../FormComponents/Create/NewCardMember";


export default function AddNewCardMemberModal({folderId, catalogId, initialOpen, onOk, onClose}) {
    const [members, setMembers] = useState([]);

    const createNewCard = async (members) => {
        await onOk(members)
    }

    return (
        <Modal open={true} width={1500} onOk={createNewCard} onCancel={onClose} okButtonProps={{style: {display: "none"}}}>
            <CreateNewCardMember folderId={folderId}
                                 catalogId={catalogId}
                                 getMembers={setMembers}
                                 initialOpen={initialOpen}
                                 createNewCard={createNewCard}
            />
        </Modal>
    )
}

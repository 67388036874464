export default class ActionsEnum{
    static get view() {
        return "A2E08E1F-E3DA-495C-87D7-A890949D7305";
    }
    static get edit() {
        return "EAC7205A-66A7-4FB4-B4C4-A26DD68EDACA";
    }
    static get create() {
        return "B5E70B19-D4A3-4F43-AA5B-6A19260C073A";
    }
    static get delete() {
        return "7CE0E4F6-4EE8-440B-A4B6-059DCABB3D20";
    }
}

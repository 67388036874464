import React from "react";
import styles from './index.module.scss'

export default function FieldItem({label, required, children, error = "", stylesWrapper = {}}) {
    return (
        <div className={styles.field} style={stylesWrapper}>
            <div className={styles.field__label}>
                {label}
                {required && (
                    <span className={styles.field_req}>
                        *
                    </span>
                )}
            </div>
            {children}
            <p className={styles.field__error}>{error}</p>
        </div>
    )
}

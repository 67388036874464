/**
 * external libs
 */
import React, {createContext, useState} from 'react';
import {useTranslation} from "react-i18next";
/**
 * locales
 */
import StorageService from "../../services/storage.service";
import detectLanguage from "../../utils/detectLanguage";


export const TranslateContext = createContext({});

export function TranslateContextProvider({children}) {
    const langCode = detectLanguage()

    const changeLanguage = (newLangCode) => {
        if(!newLangCode){
            StorageService.removeLangCode()
            window.location.reload()
            return;
        }

        StorageService.langCode = newLangCode
        window.location.reload()
    }

    return <TranslateContext.Provider value={{changeLanguage, langCode}}>{children}</TranslateContext.Provider>
}

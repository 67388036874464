/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {InputNumber} from "antd";
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * enums
 */
import FieldItem from "../../../../../Form/components/FieldItem";



export default function EditableIntNumberWidget({resultValue, isFocused = true, validationErrorMessage, widget, onEdit, field, onBlur, result}) {
    const [resultData, setResultData] = useState(result)
    const inputRef = useRef(null)

    const onBlurHandler = () => {
        if (onEdit) {
            onEdit(resultData)
        }

        if (onBlur) {
            onBlur(resultData)
        }
    }

    const onChangeHandler = ( number ) => {
        const value = `${number}`
        if(number !== null && (isNaN(+value) || value.includes('.'))){
            return null
        }

        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: number === null ? null : +value,
        } : number === null ? null : +value

        if(+value >= widget.maximum){
            onBlur({
                ...resultData,
                is_modified: true,
                measures: newMeasuresValue
            })
        }

        setResultData({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
    }

    useEffect(() => {
        setResultData(result)
    }, [result])

    useEffect(() => {
        if(isFocused){
            inputRef.current.focus()
        }
    }, [])


    return (
        <FieldItem label={widget.description || ""}  required={!widget.type.includes("null")} stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                <InputNumber rootClassName={styles.wrapper__input}
                             defaultValue={resultValue === null ? "" : (resultValue || 0)}
                             ref={inputRef}
                             suffix={widget?.['x-unit']}
                             formatter={(value) => {
                                 if(!value || !value.length){
                                     return ""
                                 }

                                 if(value.includes(".")){
                                     return resultValue || ""
                                 }

                                 return value
                             }}
                             parser={value => {
                                 if(!value.length || !value){
                                     return ""
                                 }

                                 if(value === "-"){
                                     return value
                                 }

                                 if(isNaN(+value) || value.includes('.')){
                                     let int = 1 + Math.random() * (10000 - 1);
                                     let deciaml = 1 + Math.random() * (10000 - 1);

                                     return `${ Math.round(int)}.${ Math.round(deciaml)}`
                                 }


                                 return value
                             }}
                             min={widget.exclusiveMinimum ? widget.minimum + 1 : widget.minimum}
                             max={widget.exclusiveMaximum ? widget.maximum - 1 : widget.maximum}
                             onChange={onChangeHandler}
                             onBlur={onBlurHandler}
                />
            </div>
        </FieldItem>
    )
}

import qs from 'qs'

export class QueryString {
    static stringify(obj) {
        return qs.stringify(obj, { encode: false })
    }

    static stringifyToBackand(obj) {
        const allParams = Object.keys(obj).reduce((params, key) => {
            const value = typeof obj[key] === "object" ? JSON.stringify(obj[key]) : obj[key]
            return [...params, `${key}=${value}`]
        }, [])

        return allParams.join('&')
    }

    static parseQueryString(queryString) {
        return qs.parse(queryString.slice(1))
    }
}

/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
/**
 * components
 */
import EditablePhoneWidget from "./components/Editable";
/**
 * enums
 */
import getWidget from "../../../../utils/getWidget";
import {ValidateResult} from "../../../../utils/validateResult";

export default function WidgetPhone({isFocused, result, widget, field, getError = () => {}, editable, onEdit, showDescription,}) {
    const [validationErrorMessage, setValidationErrorMessage] = useState('')

    const widgetProps = useMemo(() => {
        return field ? widget.result.schema.properties[field] : widget.result.schema;
    }, [field, widget])
    const resultValue = useMemo(() => {
        if(!result.measures){
            return null
        }

        const newResultValue = field ? result.measures?.[field] : result.measures;

        return widgetProps.type.includes(typeof newResultValue) ? newResultValue : null
    }, [widgetProps, result.measures])

    const validateResultHandler = (resultData) => {
        const {valid, message} = ValidateResult.validMeasures(resultData, widget, field)

        setValidationErrorMessage(!valid && message ? message : "")
        getError(!valid && message)
    }


    if (!result) {
        return null;
    }

    if (editable) {
        return (
            <EditablePhoneWidget widget={widgetProps}
                                 result={result}
                                 resultValue={resultValue}
                                 field={field}
                                 isFocused={isFocused}
                                 onEdit={onEdit}
                                 validationErrorMessage={validationErrorMessage}
                                 onBlur={validateResultHandler}/>
        )
    }

    if(showDescription && widgetProps.description && resultValue){
        return (
            <div>
                {widgetProps.description}: <span style={{fontWeight: 600}}>{resultValue || ""}</span>
            </div>
        )
    }

    return resultValue || ""
}

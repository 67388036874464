/**
 * external libs
 */
import React from 'react'
import Lottie from "lottie-react";
import loaderJSON from "./assets/loader.json";
/**
 * style
 */
import styles from './index.module.scss'

export default function Loader({ width = "100%", height = "100%"}){
    return (
        <div className={styles.loader} style={{width, height}}>
            <Lottie animationData={loaderJSON} loop={true} />
            {/*<img src="/image/preloader.svg" alt="preloader"/>*/}
        </div>
    )
}

/**
 * external libs
 */
import {Switch} from "react-router-dom";
import React, {Suspense, useContext, useMemo} from "react";
import {Route} from "react-router-dom";
/**
 * components
 */
import Layout from "../Layout";
/**
 * context
 */
import {AuthContext} from "../../pages/context/auth.context";
/**
 * routes
 */
import {webRoutes} from "./routes/web.routes";


export default function Routes() {
    const {user} = useContext(AuthContext)

    const routes = useMemo(() => {
        return webRoutes(user?.roles || [])
    }, [])

    return (
        <Suspense fallback={null}>
            <Switch>
                {routes.withoutLayout.map((route, i) => (
                    <route.layout exact
                                  key={i}
                                  path={route.path}
                                  component={route.component}/>
                ))}
                <Layout>
                    <Switch>
                        {routes.withLayout.map((route, i) => (
                            <route.layout exact
                                          key={i}
                                          path={route.path}
                                          component={route.component}/>
                        ))}
                    </Switch>
                </Layout>
            </Switch>
        </Suspense>
    )
}

/**
 * external libs
 */
import React, {createContext, useCallback} from 'react';
import {notification} from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const AlertContext = createContext({});

export function AlertContextProvider({children}) {
    const [api, contextHolder] = notification.useNotification();

    const setAlertSuccess = useCallback((message, duration = 3) => {
        api.open({
            message: message,
            duration: duration,
            icon: <CheckCircleOutlined  style={{ color: '#609E66' }} />,
        });
    }, []);

    const setAlertError = useCallback((message, duration = 3) => {
        api.open({
            message: message,
            duration: duration,
            icon: <CloseCircleOutlined style={{ color: '#ED1064' }} />,
        });
    }, []);

    const setAlertInfo = useCallback((message, duration = 3) => {
        api.open({
            message: message,
            duration: duration,
            icon: <InfoCircleOutlined style={{ color: '#1084FF' }} />,
        });
    }, []);

    const setAlertWarning = useCallback((message, duration = 3) => {
        api.open({
            message: message,
            duration: duration,
            icon: <WarningOutlined style={{ color: '#FFC700' }} />,
        });
    }, []);

    return (
        <AlertContext.Provider value={{setAlertSuccess, setAlertError, setAlertInfo, setAlertWarning}}>
            {contextHolder}
            {children}
        </AlertContext.Provider>
    );
}

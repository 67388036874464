import DimensionModel from "../dimension.model";
import LevelModel from "./level.model";

export default class ContentItemModel {
    constructor(data = {}) {
        this.id = data.id;
        this.name = data.name;
        this.dimension = data.dimension ? new DimensionModel(data.dimension) : null;
        this.level = data.level ? new LevelModel(data.level) : null;
    }
}

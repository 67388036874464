/**
 * external libs
 */
import React, {useEffect, useState} from 'react'
/**
 * components
 */
import EditableImageWidget from "./components/Editable";
/**
 * enums
 */
import ResultKeysEnum from "../../../../enums/api/ResultKeys";
import {useMemo} from "react";
import getWidget from "../../../../utils/getWidget";
import ResultModel from "../../../../model/medicalCard/result.model";


const Wrapper = ({description, children}) => {
    const LinkWrapper = ({ styles, children }) => {
        return (
            <div style={styles}>
                {children}
            </div>
        )
    }

    if (description) {
        return (
            <LinkWrapper styles={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                position: 'relative'
            }}>
                <div>{description}</div>
                {children}
            </LinkWrapper>
        )
    }

    return <LinkWrapper styles={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        position: 'relative',
        height: "100%"
    }}>{children}</LinkWrapper>
}

export default function WidgetImage({ result, field, widget, editable, extensions = ["png"], showDescription, onEdit, canBeLink = true }){
    const [isReady, setIsReady] = useState(false)
    const [isThumbnail, setIsThumbnail] = useState(false)
    const [isFile, setIsFile] = useState(false)

    const findFiles = () => {
        const urlFile = getUrl()

        if(!urlFile){
            return  setIsReady(true)
        }

        fetch(urlFile, {
            method: "HEAD",
        })
            .then(res => {
                if (res.status !== 200) {
                    setIsReady(true)
                    return;
                }

                setIsFile(true)

                if (!widget || !widget.result?.schema?.properties[field]?.['x-path']?.thumbnail) {
                    setIsReady(true)
                    return;
                }

                fetch(getThumbnail(), {
                    method: "HEAD"
                })
                    .then(res => {
                        if (res.status === 200) {
                            setIsReady(true)
                            setIsThumbnail(true)
                            return
                        }

                        setIsReady(true)
                        setIsThumbnail(false)
                    })
                    .catch(() => setIsReady(true))
            })
            .catch((eee) => {
                setIsReady(true)
            })
    }

    const getUrl = () => {
        return result.measures?.[field]?.url || null;
    }

    const getThumbnail = () => {
        const fileUrl = result.measures?.[field]?.url;
        const extensionFile = fileUrl.name.split('.')[fileUrl.name.split('.').length - 1]
        const [thumbnailKey] = Object.keys(widget.result.schema.properties[field]?.['x-path'].thumbnail)
        let thumbnailUrl = widget.result.schema.properties[field]?.['x-path'].thumbnail[thumbnailKey]
        thumbnailUrl = thumbnailUrl.replace('{result.id}', `${result.id.toUpperCase()}`)
        thumbnailUrl = thumbnailUrl.replace('{ext}', `${extensionFile}`)
        thumbnailUrl = thumbnailUrl.replace("{url.last}", fileUrl.split('/')[fileUrl.split('/').length - 1])
        thumbnailUrl = thumbnailUrl.replace("{url.last.noext}", fileUrl.split('/')[fileUrl.split('/').length - 1].split('.')[0])

        return thumbnailUrl
    }

    const openLinkToNewTab = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(canBeLink){
            window.open(getUrl());
        }
    }

    const autoGenerateUrl = () => {
        if(!widget){
            return null
        }

        const currentWidget  = widget.result.schema.properties[field];

        if(!currentWidget || result?.measures?.[field]?.url){
            return null
        }

        const {upload_url, auto_generate, extension} = currentWidget['x-path'];

        if(!auto_generate){
            return null
        }

        let currentUrl = upload_url;
        const [ext] = extension;
        currentUrl = currentUrl.replace("{result.id}", result.id.toUpperCase())
        currentUrl = currentUrl.replace("{token}", result.token || new ResultModel().token)
        currentUrl = currentUrl.replace("{ext}", ext)
        currentUrl = currentUrl.replace("{azure_blob_storage}", process.env.REACT_APP_AZURE_BLOB_STORAGE)

        onEdit({
            ...result,
            is_modified: true,
            measures: {
                ...result.measures,
                [field]: {
                    url: currentUrl,
                },
            }
        })
    }

    useEffect(() => {
        autoGenerateUrl()
    }, [])

    useEffect(() => {
        findFiles()
    }, [result.measures?.[field]?.url])


    if (!isReady) {
        return null
    }


    if (editable && widget && !widget.result.schema.properties[field].auto_generate) {
        return <EditableImageWidget widget={widget.result.schema.properties[field]}
                                    result={result}
                                    field={field}
                                    onEdit={onEdit}
                                    onBlur={() => {}}/>
    }



    if (!isFile && getUrl()) {
        return (
            <Wrapper description={showDescription && widget && widget.result.schema.properties[field].description}>
                {(getUrl() && canBeLink) ? (
                    <a href={getUrl()} onClick={openLinkToNewTab} target={"_blank"}>
                        <img className ="widgetImg"
                             style={{cursor: canBeLink ? "pointer" : "auto", width: 30, height: 30}}
                             src={`/image/icon/question.svg`}
                             alt=" 1i "/>
                    </a>
                ) : (
                    <img className ="widgetImg"
                         src={`/image/icon/question.svg`}
                         style={{width: 30, height: 30}}
                         alt=" 2i "/>
                )}
            </Wrapper>
        )
    }

    if (isFile) {
        return (
            <Wrapper description={showDescription && widget && widget.result.schema.properties[field].description}>
                {(getUrl() && canBeLink) ? (
                    <a href={getUrl()} target={"_blank"}>
                        <img className ="widgetImg"
                             style={{cursor: canBeLink ? "pointer" : "auto"}}
                             src={`${getUrl()}`}
                             alt=" 3i "/>
                    </a>
                ) : (
                    <img className ="widgetImg"
                         style={{cursor: canBeLink ? "pointer" : "auto"}}
                         src={`${getUrl()}`}
                         alt=" 4i "/>
                )}
            </Wrapper>
        )
    }

    if (widget && widget.result?.schema?.properties[field]?.['x-path']?.thumbnail && isThumbnail) {
        return (
            <Wrapper description={showDescription && widget && widget.result.schema.properties[field].description}>
                <img className="widgetImg"
                     style={{cursor: canBeLink ? "pointer" : "auto", width: 30}}
                     src={`${getThumbnail()}`}
                     alt=" 5i "/>
            </Wrapper>
        )
    }


    return null
}

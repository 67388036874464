/**
 * external libs
 */
import axios from 'axios';
/**
 * internal constants
 */
import { API_BASE_URL } from '../constants/api.constant';
/**
 * internal services
 */
import AuthModuleService from '../services/authModule/authModule.service';
import StorageService from "../services/storage.service";
import detectLanguage from "../utils/detectLanguage";

axios.interceptors.request.use(async (req) => {
	req.headers.Accept = 'application/json';
	req.headers["Accept-Language"] = `${detectLanguage()};q=1`

	const accessToken = await AuthModuleService.getAccessToken()
	const authMethod = AuthModuleService.getAuthMethod()


	if(accessToken){
		req.headers.Authorization = `${authMethod} ${accessToken}`;
	}

	return req;
});

axios.interceptors.response.use(
	(response) => response.data,
	async (error) => {
		if(error?.response?.status === 401){
			AuthModuleService.logout();
		}

		return Promise.reject(error?.response?.data || error);
	},
);

axios.defaults.baseURL = API_BASE_URL;

import StorageService from "../services/storage.service";
import AcceptedLanguageEnum from "../enums/AcceptedLanguage";

export default function detectLanguage(){
    if(StorageService.langCode){
        return StorageService.langCode
    }

    let currentLanguage = AcceptedLanguageEnum.en;
    window.navigator.languages.some( language => {
        if(AcceptedLanguageEnum.list.includes(language)){
            currentLanguage = language
            return true
        }
    })

    return currentLanguage;
}

import React, {useEffect, useState} from "react"
import AuthService from "../../../services/auth.service";
import AuthModules from "../index";
import AuthModuleService from "../../../services/authModule/authModule.service";
import StorageService from "../../../services/storage.service";
import AuthMethodEnum from "../../../enums/AuthMethod";

export default function CorIdentityAuth({ children }){
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        AuthModuleService.getAuth()
        setIsReady(true)
    }, [])

    if(!isReady){
        return null
    }

    return children
}

/**
 * external libs
 */
import React, {useEffect, useCallback, useRef, useState, useContext} from 'react'
import {Modal, Select} from 'antd';
import Webcam from "react-webcam";
import styles from './index.module.scss'
import DropDown from "./components/DropDown";
import Loader from "../../Loader";

export default function TakePicture({uploadFile, onClose}) {
    const webcamRef = useRef(null);
    const [currentDevice, setCurrentDevice] = useState(null);
    const [devices, setDevices] = useState([]);
    const [imageSrc, setImageSrc] = useState('');
    const [accessCamera, setAccessCamera] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [loadingCamera, setLoadingCamera] = useState(false);

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc)
    }, [webcamRef]);

    const uploadFileHandler = async () => {
        if (!imageSrc) {
            return;
        }

        await uploadFile(dataURLtoFile(imageSrc, "camera.jpeg"));
        onClose()
    }

    const handleDevices = useCallback(mediaDevices => {
        const allDevices = mediaDevices.filter(({kind}) => kind === "videoinput");
        setDevices(allDevices)
        setCurrentDevice(allDevices[0])
        setIsReady(true)
        setAccessCamera(true)
        setLoadingCamera(false);
    }, [setDevices]);


    useEffect(() => {
        setLoadingCamera(true);
        navigator.mediaDevices.getUserMedia({video: true})
            .then(function (stream) {
                if (stream.getVideoTracks().length > 0) {
                    navigator.mediaDevices.enumerateDevices().then(handleDevices);
                } else {
                    setIsReady(true);
                    setLoadingCamera(false);
                }
            })
            .catch(function (error) {
                // code for when there is an error
                setIsReady(true)
                setLoadingCamera(false);
            })
        ;
    }, [handleDevices])


    return (
        <Modal width={800}
               open={true}
               title={"Take picture"}
               onOk={uploadFileHandler}
               onCancel={onClose}
               okText={"Upload"}
               footer={[
                <div  className={styles.wrapper}>
                <DropDown devices={devices} currentDevice={currentDevice} setCurrentDevice={setCurrentDevice}/>
                       <div className={styles.actions}>
                       {imageSrc && (
                               <div className={`${styles.actions__btn} ${styles.retake}`} onClick={() => setImageSrc(null)}>
                                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M12.4839 0.476598C10.969 0.472825 9.46844 0.76953 8.06899 1.34954C6.66954 1.92956 5.39902 2.78136 4.33095 3.85565C3.45828 4.73913 2.68284 5.589 1.9206 6.47847V4.3178C1.9206 4.06311 1.81943 3.81885 1.63933 3.63876C1.45924 3.45867 1.21499 3.3575 0.9603 3.3575C0.705613 3.3575 0.461356 3.45867 0.281265 3.63876C0.101174 3.81885 0 4.06311 0 4.3178V9.1193C0 9.37399 0.101174 9.61824 0.281265 9.79833C0.461356 9.97842 0.705613 10.0796 0.9603 10.0796H5.7618C6.01649 10.0796 6.26074 9.97842 6.44083 9.79833C6.62093 9.61824 6.7221 9.37399 6.7221 9.1193C6.7221 8.86461 6.62093 8.62035 6.44083 8.44026C6.26074 8.26017 6.01649 8.159 5.7618 8.159H3.00094C3.85921 7.14828 4.71387 6.19638 5.68858 5.20968C7.02322 3.87504 8.72157 2.96331 10.5714 2.58839C12.4213 2.21348 14.3406 2.39201 16.0896 3.10168C17.8385 3.81135 19.3396 5.02069 20.4052 6.57859C21.4707 8.13649 22.0536 9.97387 22.0809 11.8611C22.1082 13.7484 21.5787 15.6019 20.5587 17.1899C19.5386 18.778 18.0732 20.0303 16.3454 20.7902C14.6177 21.5502 12.7044 21.7842 10.8445 21.4629C8.98454 21.1417 7.26053 20.2794 5.88784 18.984C5.7961 18.8973 5.68817 18.8295 5.57023 18.7845C5.4523 18.7395 5.32665 18.7182 5.20047 18.7218C5.07429 18.7253 4.95005 18.7537 4.83484 18.8053C4.71964 18.8569 4.61571 18.9306 4.52901 19.0224C4.44232 19.1141 4.37454 19.2221 4.32954 19.34C4.28455 19.4579 4.26323 19.5836 4.2668 19.7098C4.27037 19.8359 4.29875 19.9602 4.35033 20.0754C4.40191 20.1906 4.47568 20.2945 4.56743 20.3812C5.93519 21.6719 7.59807 22.6083 9.41094 23.1086C11.2238 23.6089 13.1316 23.6578 14.9677 23.2513C16.8039 22.8447 18.5126 21.9949 19.9448 20.7761C21.377 19.5572 22.4892 18.0064 23.1842 16.2589C23.8792 14.5114 24.136 12.6204 23.9321 10.7508C23.7282 8.88128 23.0698 7.09005 22.0145 5.53346C20.9591 3.97686 19.5388 2.70221 17.8775 1.82076C16.2163 0.9393 14.3645 0.477822 12.4839 0.476598Z" fill="#323238"/>
                                   </svg>
                                   Retake
                               </div>
                           )}
                           {!imageSrc && (
                               <div className={styles.actions__btn} onClick={capture}>
                                   Take screenshot
                               </div>
                           )}
                           {imageSrc && (
                               <div className={styles.actions__btn} onClick={uploadFileHandler}>
                                   Upload
                               </div>
                           )}
                       </div>
                   </div>
               ]}
        >
            {loadingCamera && <Loader width="100%" height="200px" />}
            {isReady && !accessCamera && (
                <div>
                    U dont have access to cammer. Pls give permission in browser settings
                </div>
            )}
            {isReady && accessCamera && !devices.length && (
                <div>
                    U dont have any camera. Check ur camera connection and try again
                </div>
            )}
            {!currentDevice && devices.map((device, key) => (
                <div onClick={() => setCurrentDevice(device)}>
                    <div>{device.label || `Device ${key + 1}`}</div>
                </div>
            ))}
            {currentDevice && (
                <Webcam audio={false}
                        ref={webcamRef}
                        forceScreenshotSourceSize={true}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={1}
                        videoConstraints={{deviceId: currentDevice.deviceId}}
                        style={{display: !imageSrc ? "block" : "none", width: "100%"}}
                />
            )}
            {imageSrc && (
                <img src={imageSrc} style={{width: "100%", height: "auto"}} alt=""/>
            )}
        </Modal>
    )
}

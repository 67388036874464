import MemberModel from "../member.model";
import random from '../../utils/random'
import getWidget from "../../utils/getWidget";
import {v4 as uuidv4} from 'uuid';

export default class ResultModel {
    constructor(data = {}) {
        this.id = data.id || uuidv4();
        this.recordId = data.recordId;
        this.measures = data.measures ?? null;
        this.member = data.member ? data.member.map( member => new MemberModel(member)) : [];
        this.recordMembers = data.recordMembers || []
        this.is_modified = window.location.pathname.split('/')[1] === "new";
        this.allMembers = [
            ...(data.medCardMembers || []),
            ...(data.recordMembers || []),
            ...(this.member || []),
        ]
    }


    get token(){
        return btoa(random(18))
    }
}

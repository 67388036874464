/**
 *service
 */
import axios from 'axios'
/**
 * const
 */
import {
    API_LIST_FOLDERS_URL,
    API_UPDATE_FOLDERS_URL,
    API_CREATE_FOLDERS_URL,
    API_DELETE_FOLDERS_URL,
    API_LIST_TRANSLATION_URL,
    API_CREATE_TRANSLATION_URL, API_GET_SETTINGS
} from "../constants/api.constant";
import {QueryString} from "../utils/Querystring";
import FolderModel from "../model/medicalCard/folder.model";


export default class SettingsService {
    static async get(params = {}) {
        return await axios.get(API_GET_SETTINGS, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .catch(e => Promise.reject(e))
    }
}

export default class RolesEnums {
  static get contentReader() {
    return "D269F6FA-9241-4EAF-B202-A33EA7FD6F9B";
  }
  static get administrator() {
    return "D03164A8-ADBB-494A-A14E-A6B316C0F485";
  }
  static get contentManager() {
    return "1E7B8A32-B82A-47E1-A861-B74712576AD4";
  }
}

import AuthMethodEnum from "../enums/AuthMethod";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_signup",
        editProfile: "B2C_1_edit_profile",
        resetPassword: "B2C_1_reset_password"
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${process.env.REACT_APP_AZURE_B2C_DOMAIN}.b2clogin.com/${process.env.REACT_APP_AZURE_B2C_DOMAIN}.onmicrosoft.com/B2C_1_signup`,
        },
        editProfile: {
            authority: `https://${process.env.REACT_APP_AZURE_B2C_DOMAIN}.b2clogin.com/${process.env.REACT_APP_AZURE_B2C_DOMAIN}.onmicrosoft.com/B2C_1_edit_profile`
        },
        resetPassword: {
            authority: `https://${process.env.REACT_APP_AZURE_B2C_DOMAIN}.b2clogin.com/${process.env.REACT_APP_AZURE_B2C_DOMAIN}.onmicrosoft.com/B2C_1_reset_password`
        }
    },
    authorityDomain: `${process.env.REACT_APP_AZURE_B2C_DOMAIN}.b2clogin.com`
};

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: `${window.location.origin}/auth/${AuthMethodEnum.azure}`,
    },
    cache: {
        cacheLocation: "localStorage"
    }
}


/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import EditableTimeWidget from "./components/Editable";
/**
 * utils
 */
import DateTime from "../../../../utils/DateTime";
import ResultKeysEnum from "../../../../enums/api/ResultKeys";
import {useMemo} from "react";
import getWidget from "../../../../utils/getWidget";

export default function WidgetTime({ result, field, widget, editable, showDescription, onEdit }){
    const widgetProps = useMemo(() => {
        return field ? widget.result.schema.properties[field] : widget.result.schema;
    }, [field, widget])
    const resultValue = useMemo(() => {
        if(!result.measures){
            return null
        }

        const newResultValue = field ? result.measures?.[field] : result.measures;

        return widgetProps.type.includes(typeof newResultValue) ? newResultValue : null
    }, [widgetProps, result.measures])

    if(!result){
        return null
    }

    if(editable){
        return (
            <EditableTimeWidget result={result} widget={widgetProps} resultValue={resultValue} field={field} onEdit={onEdit} onBlur={() => {}}/>
        )
    }

    if(showDescription && widgetProps.description && resultValue){
        return (
            <div>
                {widgetProps.description}: <span style={{fontWeight: 600}}>{resultValue ? DateTime.timeToHis(new Date(resultValue)) : null}</span>
            </div>
        )
    }

    return resultValue ? DateTime.timeToHis(new Date(resultValue)) : null
}

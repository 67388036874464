/**
 * external libs
 */
import React, {createContext, useCallback, useState} from 'react';
import {notification} from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const GlobalFolderContext = createContext({});

export function GlobalFolderProvider({children}) {
    const [folder, setFolder] = useState(null);

    return (
        <GlobalFolderContext.Provider value={{globalFolder: folder, setGlobalFolder: setFolder}}>
            {children}
        </GlobalFolderContext.Provider>
    );
}

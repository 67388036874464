import React, {useState} from 'react'
import styles from './index.module.scss'

export default function DropDown({devices, currentDevice, setCurrentDevice}) {
    const [openOptions, setOpenOptions] = useState(false);

    const clickOnDevice = (device) => {
        setCurrentDevice(device)
        setOpenOptions(false)
    }

    if(!currentDevice){
        return null
    }

    return (
        <div className={styles.popup}>
            <div className={styles.popup__line} onClick={() => setOpenOptions(!openOptions)}>
                <div>
                    {currentDevice.label}
                </div>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                     style={{transform: "translateY(-1px)"}}>
                    <path d="M13 3L7 9L1 3" stroke="#323238" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </div>
            <div className={`${styles.popup__options} ${openOptions && styles.open}`}>
                {devices.map( device => (
                    <div className={styles.popup__item} key={device.deviceId} onClick={() => clickOnDevice(device)}>
                        <div className={`${styles.popup__radio} ${device.deviceId === currentDevice.deviceId && styles.active}`}/>
                        {device.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

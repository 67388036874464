export default class IncarnationEnum {

  static get id() {
    return {
      card: "CD7C92C5-0945-40ED-A86B-7969BF9A36A6",
      cardHolder:"88BFFC99-7856-45E5-B715-0AC4920E63E2",
      cardHolderRelation: "854586A9-4B3B-4E5E-BBC3-DB59DCE6F3EF",
      cardHolderStatus: "829A2EF7-BABA-4B8E-859D-9E98297C79E7",
      gender: "3C1E3908-B38B-4E28-AC2B-757AA87FC947",
      record: "F9085EB0-9472-4F9C-B054-4CF88C8E40E1",
      recordType: "BCDFE915-768E-4EEB-B791-88EE12CFEAC7",
      result: "42D9D1F3-E9AC-472C-8FED-34FC647EC881",
      resultType: "E0596B81-97BF-4787-B712-FFB6670393CF",
      securityInstance: "2C358FA5-57AD-43EB-A22E-107D6E20575E",
      folder: "CCA68680-EEF7-47B2-BFEA-3DECEAE90876",
    }
  }

  static get name() {
    return {
      card: "[medical].[card]",
      cardHolder:"[medical].[card_holder]",
      cardHolderRelation: "[medical].[card_holder_relation]",
      cardHolderStatus: "[medical].[card_holder_status]",
      gender: "[medical].[gender]",
      record: "[medical].[record]",
      recordType: "[medical].[record_type]",
      result: "[medical].[result]",
      resultType: "[medical].[result_type]",
      securityInstance: "[security].[instance]",
      folder: "[frontend].[folder]",
    }
  }

  static getIdByName( incarnationName ){
    const findKey = Object.keys(IncarnationEnum.name)
        .find( key => IncarnationEnum.name[key] === incarnationName)

    return IncarnationEnum.id[findKey]
  }

  static getNameById( incarnationId ){
    const findKey = Object.keys(IncarnationEnum.id)
        .find( key => IncarnationEnum.id[key] === incarnationId)

    return IncarnationEnum.name[findKey]
  }
}

export default class WarningModel {
    constructor(data = {}) {
        this.translations = [];

        if (data.translation && Array.isArray(data.translation)) {
            this.translations = data.translation.map(translation => {
                return {
                    value: translation.value,
                    key: translation.key,
                    language: translation.language && translation.language.length > 0 ? translation.language[0].code : null
                };
            });
        }
    }
}
/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
/**
 * components
 */
import SearchLineMembers from "./components/Memebrs";
import SearchLineSearch from "./components/Search";
/**
 * styles
 */
import styles from './index.module.scss'

export default function SelectMembersSearchLine({getScanResult, isRequired, getIsFinish, selectedMembers, setSelectedMembers, openSelectMember, getMembersData, setOpenSelectMember}){
    const onSearch = async ( searchValue ) => {
        await getMembersData({
            free_text: searchValue
        })
    }

    const clearSelectedMembers = async () => {
        setSelectedMembers([])
        await getMembersData()
    }

    const openSelectedMember = async () => {
        setOpenSelectMember(!openSelectMember)
        getIsFinish(openSelectMember)
        if(!openSelectMember){
            await getMembersData({members: selectedMembers.filter(({columnOptions}) => !columnOptions.is_multiple).map(({id}) => id)})
        }
    }

    return (
        <div className={styles.searchLine}>
            {!!selectedMembers.length && (
                <SearchLineMembers  selectedMembers={selectedMembers}
                                    setSelectedMembers={setSelectedMembers}
                                    setOpenSelectMember={setOpenSelectMember}
                                    getMembersData={getMembersData}
                                    openSelectMember={openSelectMember}/>
            )}
            <SearchLineSearch onSearch={onSearch} getScanResult={getScanResult}/>
            <svg onClick={clearSelectedMembers} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.166 21.834L21.8333 10.1667" stroke="#323238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.166 10.166L21.8333 21.8333" stroke="#323238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {!isRequired && (
                <svg style={{transform: openSelectMember ? "rotate(0)" : "rotate(180deg)"}} onClick={openSelectedMember} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.5 13L16 20.5L8.5 13" stroke="#323238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )}
        </div>
    )
}

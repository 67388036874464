/**
 * external libs
 */
import React, {useContext, useEffect, useState} from 'react'
import {BlobServiceClient} from "@azure/storage-blob";
/**
 * styles
 */
import {AlertContext} from "../../../../../../pages/context/alert.context";
import DateTime from "../../../../../../utils/DateTime";
import ResultModel from "../../../../../../model/medicalCard/result.model";
import TakePicture from "../../../../../Modal/TakePicture";
import LinkEdit from './components/LinkEdit'
import FieldItem from "../../../../../Form/components/FieldItem";
import getWidget from "../../../../../../utils/getWidget";

export default function EditableImageWidget({onEdit, field, widget, onBlur, result}) {
    const [openTakePicture, setOpenTakePicture] = useState(false);
    const [openChangeLink, setOpenChangeLink] = useState(false);
    const {setAlertSuccess} = useContext(AlertContext)
    const [resultData, setResultData] = useState(result)

    const uploadFile = async (file) => {
        const {upload_url} = widget['x-path'];
        const extensionFile = file.name.split('.')[file.name.split('.').length - 1]
        const azureContainerImage = upload_url.split('/')[upload_url.split('/').length - 2]
        const blobServiceClient = new BlobServiceClient(`https://${process.env.REACT_APP_AZURE_BLOB_STORAGE}?${process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN_IMAGE_CONTAINER}`);

        let currentUrl = upload_url;
        currentUrl = currentUrl.replace("{result.id}", result.id.toUpperCase())
        currentUrl = currentUrl.replace("{token}", result.token || new ResultModel().token)
        currentUrl = currentUrl.replace("{ext}", extensionFile)
        currentUrl = currentUrl.replace("{azure_blob_storage}", process.env.REACT_APP_AZURE_BLOB_STORAGE)

        const containerClient = blobServiceClient.getContainerClient(azureContainerImage);

        const uploadOptions = {};

        if (extensionFile === "svg") {
            uploadOptions.blobHTTPHeaders = {
                blobContentType: "image/svg+xml"
            }
        }

        if (["png", "jpg", "jpeg"].includes(extensionFile)) {
            uploadOptions.blobHTTPHeaders = {
                blobContentType: `image/${extensionFile}`
            }
        }

        if (extensionFile === "pdf") {
            uploadOptions.blobHTTPHeaders = {
                blobContentType: "application/pdf"
            }
        }


        const blockBlobClient = containerClient.getBlockBlobClient(currentUrl.split('/')[currentUrl.split('/').length - 1]);
        await blockBlobClient.uploadBrowserData(file, uploadOptions);
        setAlertSuccess('File uploaded!');

        return {
            name: file.name,
            url: currentUrl,
            created_at: DateTime.dateToBackendFormat(new Date(resultData.measures?.[field]?.created_at || new Date())),
            modified_at: DateTime.dateToBackendFormat(new Date())
        }

    }

    const onFileChange = async (e) => {
        const files = e.target.files;
        Promise.all(new Array(files.length).fill(null).map((_, index) => uploadFile(files[index])))
            .then(uploadedFiles => {
                const currentFiles = resultData.measures?.[field]

                onEdit({
                    ...resultData,
                    is_modified: true,
                    measures: {
                        ...resultData.measures,
                        [field]: widget.type.includes('array') ? [...(currentFiles || []), ...uploadedFiles] : uploadedFiles[0],
                    }
                })
            })
    }

    const onLinkChange = (currentUrl) => {
        const file = {
            name: "file",
            url: currentUrl,
        }
        const currentFiles = resultData.measures?.[field]

        onEdit({
            ...resultData,
            is_modified: true,
            measures: {
                ...resultData.measures,
                [field]: widget.type.includes('array') ? [...(currentFiles || []), file] : file,
            }
        })
    }

    const onPhotoChange = async (currentFile) => {
        const file = await uploadFile(currentFile)
        const currentFiles = resultData.measures?.[field]

        onEdit({
            ...resultData,
            is_modified: true,
            measures: {
                ...resultData.measures,
                [field]: widget.type.includes('array') ? [...(currentFiles || []), file] : file,
            }
        })
    }

    useEffect(() => {
        setResultData(result)
    }, [result])

    return (
        <div>
            <FieldItem label={widget.description || ""} required={!widget.type.includes("null")}
                       stylesWrapper={{marginBottom: 10, position: "relative", zIndex: 11}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <label htmlFor={"file"}>
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"
                             style={{cursor: "pointer"}}>
                            <path
                                d="M31.1538 11H10.8462C10.3565 11 9.88695 11.1945 9.54073 11.5407C9.1945 11.8869 9 12.3565 9 12.8462V29.4615C9 29.9512 9.1945 30.4207 9.54073 30.767C9.88695 31.1132 10.3565 31.3077 10.8462 31.3077H31.1538C31.6435 31.3077 32.1131 31.1132 32.4593 30.767C32.8055 30.4207 33 29.9512 33 29.4615V12.8462C33 12.3565 32.8055 11.8869 32.4593 11.5407C32.1131 11.1945 31.6435 11 31.1538 11ZM31.1538 12.8462V24.7019L28.1458 21.695C27.9743 21.5235 27.7708 21.3875 27.5468 21.2947C27.3228 21.2019 27.0827 21.1541 26.8402 21.1541C26.5977 21.1541 26.3576 21.2019 26.1336 21.2947C25.9096 21.3875 25.7061 21.5235 25.5346 21.695L23.2269 24.0027L18.15 18.9258C17.8038 18.5798 17.3344 18.3855 16.845 18.3855C16.3556 18.3855 15.8862 18.5798 15.54 18.9258L10.8462 23.6196V12.8462H31.1538ZM10.8462 26.2308L16.8462 20.2308L26.0769 29.4615H10.8462V26.2308ZM31.1538 29.4615H28.6881L24.5342 25.3077L26.8419 23L31.1538 27.3131V29.4615ZM22.8462 17.9231C22.8462 17.6492 22.9274 17.3815 23.0795 17.1538C23.2316 16.9261 23.4479 16.7487 23.7009 16.6439C23.9539 16.5391 24.2323 16.5116 24.5009 16.5651C24.7695 16.6185 25.0162 16.7504 25.2098 16.944C25.4035 17.1376 25.5354 17.3844 25.5888 17.653C25.6422 17.9215 25.6148 18.1999 25.51 18.4529C25.4052 18.706 25.2277 18.9222 25 19.0743C24.7723 19.2265 24.5046 19.3077 24.2308 19.3077C23.8635 19.3077 23.5114 19.1618 23.2517 18.9021C22.992 18.6425 22.8462 18.2903 22.8462 17.9231Z"
                                fill="#323238"/>
                        </svg>
                        <input id={"file"}
                               type="file"
                               multiple={widget.type.includes('array')}
                               onChange={onFileChange}
                               hidden={true}/>
                    </label>
                    {['svg', 'png', 'webp', 'jpg', 'jpeg'].some(ext => widget['x-path'].extension.includes(ext)) && (
                        <div onClick={() => setOpenTakePicture(!openTakePicture)}>
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 style={{cursor: "pointer"}}>
                                <path
                                    d="M29.75 30.1875H12.25C11.7859 30.1875 11.3408 30.0031 11.0126 29.6749C10.6844 29.3468 10.5 28.9016 10.5 28.4375V16.1875C10.5 15.7234 10.6844 15.2783 11.0126 14.9501C11.3408 14.6219 11.7859 14.4375 12.25 14.4375H15.75L17.5 11.8125H24.5L26.25 14.4375H29.75C30.2141 14.4375 30.6592 14.6219 30.9874 14.9501C31.3156 15.2783 31.5 15.7234 31.5 16.1875V28.4375C31.5 28.9016 31.3156 29.3468 30.9874 29.6749C30.6592 30.0031 30.2141 30.1875 29.75 30.1875Z"
                                    stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path
                                    d="M20.999 25.8125C23.1736 25.8125 24.9365 24.0496 24.9365 21.875C24.9365 19.7004 23.1736 17.9375 20.999 17.9375C18.8244 17.9375 17.0615 19.7004 17.0615 21.875C17.0615 24.0496 18.8244 25.8125 20.999 25.8125Z"
                                    stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    )}
                    {widget['x-path'].direct_link && (
                        <div style={{position: "relative"}}>
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 onClick={() => setOpenChangeLink(!openChangeLink)} style={{cursor: "pointer"}}>
                                <path
                                    d="M19.3906 14.0818L20.943 12.1158C22.312 10.3821 24.8274 10.0864 26.5612 11.4555L27.8756 12.4934C29.6094 13.8625 29.9051 16.3778 28.536 18.1116L25.3551 22.1399C24.0161 23.8356 21.5725 24.1615 19.836 22.876L18.3022 21.7406"
                                    stroke="#323238" stroke-width="2" stroke-linecap="round"/>
                                <path
                                    d="M22.1394 18.5247L21.1585 17.8555C19.4229 16.6715 17.067 17.032 15.7649 18.6809L12.519 22.7916C11.1499 24.5254 11.4456 27.0407 13.1794 28.4098L14.4944 29.4482C16.2282 30.8173 18.7436 30.5216 20.1126 28.7879L21.6521 26.8383"
                                    stroke="#323238" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            {openChangeLink && (
                                <LinkEdit onLinkChange={onLinkChange} url={resultData?.measures?.[field]?.url}
                                          setOpenChangeLink={setOpenChangeLink}/>
                            )}
                        </div>
                    )}
                </div>
                <div style={{paddingLeft: 10}}>
                    {widget.type.includes('array') && resultData?.measures?.[field] ? (
                        <div>
                            {resultData?.measures?.[field].map((file, index) => (
                                <div>
                                    №{index + 1} {file?.name}
                                </div>
                            ))}
                        </div>
                    ) : resultData?.measures?.[field]?.name ? (
                        <div>{resultData?.measures?.[field]?.name}</div>
                    ) : null}
                </div>
            </FieldItem>

            {openTakePicture && (
                <TakePicture uploadFile={onPhotoChange} onClose={() => setOpenTakePicture(false)}/>
            )}
        </div>
    )
}

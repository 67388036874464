/**
 *service
 */
import axios from 'axios'
/**
 * const
 */
import {
    API_CATALOG_CONTENT_LIST,
    API_LIST_CATALOG_URL,
    API_MAKE_CSV_CATALOG_URL,
    API_MAKE_DELETE_CATALOG_URL,
    API_MAKE_GET_CATALOG_URL,
    API_UPLOAD_CATALOG_URL,
    API_MAKE_EDIT_CATALOG_URL,
} from "../constants/api.constant";
/**
 * utils
 */
import {QueryString} from "../utils/Querystring";
/**
 * model
 */
import FolderModel from "../model/medicalCard/folder.model";
import ContentModel from "../model/catalog/content/content.model";
import CatalogModel from "../model/catalog/catalog.model";


export default class CatalogService {
    static async content(params = {}) {
        return await axios.get(API_CATALOG_CONTENT_LIST, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .then((catalogContent) => new ContentModel(catalogContent))
            .catch(e => Promise.reject(e))
    }

    static async list(params = {}) {
        return await axios.get(API_LIST_CATALOG_URL, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .then((catalogs) => catalogs.map(catalog => new CatalogModel(catalog)))
            .catch(e => Promise.reject(e))
    }
    
    static async edit(catalogId, params = {}) {
        return await axios.patch(API_MAKE_EDIT_CATALOG_URL(catalogId), params)
            .catch(e => Promise.reject(e));
    }

    static async get(catalogId, params = {}) {
        return await axios.get(API_MAKE_GET_CATALOG_URL(catalogId), {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .then((catalog) => new CatalogModel(catalog))
            .catch(e => Promise.reject(e))
    }

    static async upload(data, params = {}) {
        await axios.post(API_UPLOAD_CATALOG_URL, data)
            .catch(e => Promise.reject(e))
    }

    static async getCSV(catalogId, params = {}) {
        return await axios.get(API_MAKE_CSV_CATALOG_URL(catalogId), {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .catch(e => Promise.reject(e))
    }

    static async delete(catalogId) {
        return await axios.delete(API_MAKE_DELETE_CATALOG_URL(catalogId))
            .catch(e => Promise.reject(e))
    }
}

/**
 *service
 */
import axios from 'axios'
/**
 *service
 */
import StorageService from "../../storage.service";
/**
 * const
 */
import {jwtDecode} from "jwt-decode";
import {InteractionRequiredAuthError} from "@azure/msal-browser";
import {b2cPolicies} from "../../../authConfig/azure";

export default class AzureService {
    static instance = {};

    static async getAccessToken() {
        const tokenRequest = {
            scopes: [`https://${process.env.REACT_APP_AZURE_B2C_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_AZURE_B2C_SYNC_TOKEN}/data.sync`],
        };

        if (!this.instance) {
            return null
        }


        return this.instance.acquireTokenSilent(tokenRequest)
            .then(response => response.accessToken)
            .catch(async error => {
                if (error instanceof InteractionRequiredAuthError) {
                    return await this.getRefreshToken()
                }

                return null;
            });
    }

    static async getRefreshToken() {
        const tokenRequest = {
            scopes: [`https://${process.env.REACT_APP_AZURE_B2C_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_AZURE_B2C_SYNC_TOKEN}/data.sync`],
        };

        return this.instance.acquireTokenRedirect(tokenRequest)
            .then(response => {
                return response.accessToken;
            })
            .catch(err => {
                return null;
            });
    }


    static isAuthenticate() {
        return !!this.instance.getActiveAccount()
    }


    static async redirectToResetPassword() {
        await this.instance.loginPopup({
            authority: b2cPolicies.authorities.resetPassword.authority,
            scopes: ["openid"]
        });
    }

    static async redirectToLogin() {
        await this.instance.loginRedirect({
            scopes: [`https://${process.env.REACT_APP_AZURE_B2C_DOMAIN}.onmicrosoft.com/${process.env.REACT_APP_AZURE_B2C_SYNC_TOKEN}/data.sync`]
        })
    }

    static validToken(token) {
        try {
            const {exp} = jwtDecode(token)

            if (!exp) {
                return false
            }

            const expTime = exp * 1000;
            const dateNow = new Date().getTime();

            return dateNow < expTime;
        } catch (e) {
            return false
        }
    }

    static logout() {
        StorageService.removeAccessToken()
        StorageService.removeRefreshToken()
        StorageService.removeActiveAuthMethod()

        this.instance.logoutRedirect(() => {})
    }

    static getAuthMethod(){
        return "Bearer"
    }
}

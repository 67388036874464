/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import SideBarItem from '../Item'


export default function ChildrenItem({ items, activeFolder, disableFolders, openSideBar}){
    return (
        <div style={{paddingLeft: 20, paddingBottom: 30}}>
            {items.map((item, index) => (
                <SideBarItem key={index} index={index} activeFolder={activeFolder} disableFolders={disableFolders} item={item} openSideBar={openSideBar}/>
            ))}
        </div>
    )
}

 /**
 * external libs
 */
 import React, {useEffect, useState} from 'react'
 /**
  * styles
  */
 import styles from './index.module.scss'


 export default function GetIcon({ url, backendUrl, svg = null, width = "100%", height = "100%" }){
     const [imageFromUrl, setImageFromUrl] = useState(null)
     const [svgFromUrl, setSvgFromUrl] = useState(null)

     const getImage = () => {
         if(svg){
             return null
         }

         // || backendUrl !== "svg"
         if(url){
             return setImageFromUrl(url)
         }

         fetch(backendUrl)
             .then(response => {
                 if(response.status !== 200){
                     return ;
                 }

                 return response.text()
             })
             .then(svg => {
                 if(!svg){
                     return;
                 }

                 setSvgFromUrl(svg)
             })
             .catch(e => console.error(e));
     }

     useEffect(() => {
         getImage()
     }, [backendUrl, url, svg])

     if(imageFromUrl){
         return  (
             <div className={styles.wrapper}>
                 <img src={imageFromUrl} alt="icon"/>
             </div>
         )
     }

     if(svgFromUrl){
         return  <div className={styles.wrapper}  dangerouslySetInnerHTML={{ __html: svgFromUrl }} />

     }

     if(svg){
         return  (
             <div className={styles.wrapper}>
                 {svg}
             </div>
         )
     }

     return null
 }

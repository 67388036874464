import WidgetService from "../../services/widget.service";

class WidgetsByMembersId {
    widget = null;

    async getWidget(){
        this.widget = await WidgetService.listFrontend()
    }
}

const widget = new WidgetsByMembersId()

export default widget;

/**
 * external libs
 */
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
/**
 * componentx
 */
import WidgetString from './components/WidgetString'
import WidgetDate from './components/WidgetDate'
import WidgetNumber from './components/WidgetNumber'
import WidgetLastMember from './components/WidgetLastMember'
/**
 * utils
 */
import getWidget from "../../utils/getWidget";
/**
 * enums
 */
import WidgetURL from "./components/WidgetURL";
import WidgetNameEnum from "../../enums/api/WidgetName";
import WidgetTime from "./components/WidgetTime";
import WidgetDateTime from "./components/WidgetDateTime";
import WidgetIntNumber from "./components/WidgetIntNumber";
import WidgetPhone from "./components/WidgetPhone";
import WidgetLink from "./components/WidgetLink";
import {useTranslation} from "react-i18next";
import WidgetMask from "./components/WidgetMask";
import {AlertContext} from "../../pages/context/alert.context";
import LocalWidgetWrapper from "./LocalWidgetWrapper";
import {Validator} from "jsonschema";

function extractValuesFromMask(str) {
    const regex = /\{(.*?)\}/g;
    let values = [];
    let match;

    while ((match = regex.exec(str)) !== null) {
        values.push(match[1]);
    }

    return values;
}

export default function Widget({
                                   result,
                                   onCancel,
                                   excludeWidget = [],
                                   readOnly = true,
                                   catalogId = null,
                                   onEdit,
                                   setEditableId = () => {},
                                   isButtonHandler,
                                   editable,
                                       initialFocused,
                                   getError,
                                   widgetDataError,
                                    stylesWrapper = {},
                                   ...props
                               }) {
    const containerRef = useRef(null)
    const {setAlertError} = useContext(AlertContext)
    const [isEditableMode, setIsEditableMode] = useState(editable)
    const [newResult, setNewResult] = useState(result)
    const [widget, setWidget] = useState(null);
    const properties = useMemo(() => {
        if (!widget) {
            return [];
        }

        if(!widget.result.schema.properties){
            return []
        }

        const allProperties = Object.keys(widget.result.schema.properties);

        if (isEditableMode) {
            return allProperties;
        }

        if (widget.result?.widget) {

            if(!result?.measures){
                return allProperties;
            }

            const matchValueInMask = extractValuesFromMask(widget.result.widget.mask)
            let countOfNoValue = matchValueInMask.reduce((countOfNoValue, key) => {
                return !Object.keys(result.measures).includes(key) ? countOfNoValue + 1 : countOfNoValue;
            }, 0);

            return countOfNoValue === matchValueInMask.length ? allProperties : widget.result.widget.measures || [];
        }

        return allProperties;
    }, [widget, isEditableMode, result?.measures])
    const {t} = useTranslation();

    const haveValue = useMemo(() => {
        if (!widget || !newResult) {
            return false
        }

        if(widget.result.schema.properties){
            return Object.keys(widget.result.schema.properties).some((field) => {
                const value = newResult.measures?.[field];

                return Boolean(value || (field === "last_member"))
            });
        }

        if(widget.result.schema){
            return newResult.measures !== null
        }

        return false
    }, [widget, newResult])

    const editLocalWidget = (result) => {
        setNewResult(result)


        if (!isButtonHandler) {
            onEdit(result)
        }
    }

    const editButtonHandler = async () => {
        await onEdit(newResult)
    }

    const onCancelHandler = () => {
        onCancel()
        setNewResult(result)
    }

    const editableModeHandler = () => {
        if (readOnly || isEditableMode) {
            return;
        }

        if(widgetDataError){
            return setAlertError("You must fix the validation error");
        }

        setEditableId(result.id)
    }

    const changeMembersHandler = (newResult) => {
        if(!widget){
            return
        }

        const copyResult = JSON.parse(JSON.stringify(newResult))
        const memberCount = copyResult.member.length;
        const allMembersCount = copyResult.allMembers.length;

        copyResult.allMembers = copyResult.allMembers.filter((_, index) => (index + 1) < (allMembersCount - memberCount))
        copyResult.allMembers = [...copyResult.allMembers, ...copyResult.member]

        const newWidget = getWidget(copyResult);

        if(newWidget.id !== widget.id){
            copyResult.measures = null
        }

        setWidget(getWidget(copyResult))
        setNewResult(copyResult)
        if (!isButtonHandler) {
            onEdit(copyResult)
        }
    }

    useEffect(() => {
        if(!widget){
            setWidget(getWidget(result))
        }
        setNewResult(result)
    }, [result])

    useEffect(() => {
        setWidget(getWidget(result))
    }, [result?.allMembers])

    useEffect(() => {
        setIsEditableMode(editable)
    }, [editable])

    useEffect(() => {
        const getActionsDetect = (e) => {
            const keyPressed = e.keyCode || e.which;

            if (!isEditableMode) {
                return;
            }

            if (keyPressed === 13 && isButtonHandler) {
                document.activeElement.blur();
                editButtonHandler()
            }

            if (keyPressed === 27) {
                onCancelHandler()
            }
        }

        if (!readOnly && isEditableMode) {
            document.addEventListener("keydown", getActionsDetect)
        }

        return () => document.removeEventListener("keydown", getActionsDetect)
    }, [readOnly, isEditableMode, editButtonHandler, onCancelHandler])

    return (
        <div  ref={containerRef}>
            {!!result && !!widget && (
                <div onClick={editableModeHandler} style={(!haveValue && !readOnly) ? {width: "100%", minHeight: 50} : {}}>
                    {!isEditableMode && widget.result.widget && (
                        <WidgetMask result={newResult}
                                    extractValuesFromMask={extractValuesFromMask}
                                    getError={getError}
                                    widget={widget}
                                    {...props}/>
                    )}
                    {!properties.length && (
                        <LocalWidgetWrapper widget={widget}
                                            initialFocused={initialFocused}
                                            excludeWidget={excludeWidget}
                                            newResult={newResult}
                                            isEditableMode={isEditableMode}
                                            readOnly={readOnly}
                                            editLocalWidget={editLocalWidget}
                                            getError={getError}
                                            catalogId={catalogId}
                                            changeMembersHandler={changeMembersHandler}
                                            {...props}
                        />
                    )}
                    {properties.map((field, index) => (
                        <LocalWidgetWrapper widget={widget}
                                            initialFocused={initialFocused}
                                            excludeWidget={excludeWidget}
                                            newResult={newResult}
                                            isEditableMode={isEditableMode}
                                            readOnly={readOnly}
                                            field={field}
                                            editLocalWidget={editLocalWidget}
                                            index={index}
                                            catalogId={catalogId}
                                            getError={getError}
                                            changeMembersHandler={changeMembersHandler}
                                            {...props}
                        />
                    ))}
                    {isButtonHandler && isEditableMode && (
                        <div style={{display: "flex", alignItems: "center", gap: 10}}>
                            <div onClick={editButtonHandler} style={{
                                padding: 10,
                                border: "1px solid #dadada",
                                borderRadius: 10,
                                textAlign: "center",
                                cursor: "pointer",
                            }}>
                                {t("SAVE")}
                            </div>
                            <div onClick={onCancelHandler} style={{
                                padding: 10,
                                border: "1px solid #dadada",
                                borderRadius: 10,
                                textAlign: "center",
                                cursor: "pointer",
                            }}>
                                {t("CANCEL")}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}


import DimensionModel from "../dimension.model";

export default class LevelModel {
    constructor(data = {}) {
            this.id = data.id;
            this.ordinal = data.ordinal;
            this.is_required = data.is_required;
            this.is_multiple = data.is_multiple;
            this.is_restricted = data.is_restricted;
            this.dimension = data.dimension && new DimensionModel(data.dimension);
    }
}

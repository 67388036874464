/**
 * external libs
 */
import React, {useMemo, useState} from 'react'
/**
 * components
 */
import EditableLinkWidget from "./components/Editable";
/**
 * enums
 */
import getWidget from "../../../../utils/getWidget";
import {ValidateResult} from "../../../../utils/validateResult";

export default function WidgetLink({ isFocused, result, field, widget, getError= () => {}, editable, onEdit, showDescription, }){
    const [validationErrorMessage, setValidationErrorMessage] = useState('')

    const validateResultHandler = (resultData) => {
        const {valid, message} = ValidateResult.validMeasures(resultData, widget, field)

        setValidationErrorMessage(!valid && message ? message : "")
        getError(!valid && message)
    }

    if(!result){
        return null;
    }

    if(editable){
        return (
            <EditableLinkWidget widget={widget.result.schema.properties[field]}
                                  result={result}
                                  field={field}
                                isFocused={isFocused}
                                  onEdit={onEdit}
                                validationErrorMessage={validationErrorMessage}
                                  onBlur={validateResultHandler}/>
        )
    }

    if(showDescription && widget.result.schema.properties[field].description && result.measures?.[field]){
        return (
            <div>
                {widget.result.schema.properties[field].description}: <span style={{fontWeight: 600}}>{result.measures?.[field] || ""}</span>
            </div>
        )
    }

    return result.measures?.[field] || ""
}

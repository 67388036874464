/**
 * external libs
 */
import React, {useState} from 'react'
/**
 * components
 */
import EditableIntNumberWidget from "./components/Editable";
/**
 * enums
 */
import ResultKeysEnum from "../../../../enums/api/ResultKeys";
import {useMemo} from "react";
import getWidget from "../../../../utils/getWidget";
import {ValidateResult} from "../../../../utils/validateResult";

export default function WidgetIntNumber({isFocused, result, field, editable, widget, getError= () => {}, onEdit, showDescription = true}) {
    const [validationErrorMessage, setValidationErrorMessage] = useState('')

    const widgetProps = useMemo(() => {
        return field ? widget.result.schema.properties[field] : widget.result.schema;
    }, [field, widget])
    const resultValue = useMemo(() => {
        if([null, undefined].includes(result.measures)){
            return null
        }

        const newResultValue = field ? result.measures?.[field] : result.measures;

        return  newResultValue ?? null;
        // return widgetProps.type.includes(typeof newResultValue) ? newResultValue : null
    }, [widgetProps, result.measures, field])

    const validateResultHandler = (resultData) => {
        const {valid, message} = ValidateResult.validMeasures(resultData, widget, field)

        setValidationErrorMessage(!valid && message ? message : "")
        getError(!valid && message)
    }

    if (!result) {
        return null
    }

    if (editable) {
        return (
            <EditableIntNumberWidget widget={widgetProps}
                                     result={result}
                                     field={field}
                                     onEdit={onEdit}
                                     isFocused={isFocused}
                                     resultValue={resultValue}
                                     validationErrorMessage={validationErrorMessage}
                                     onBlur={validateResultHandler}/>
        )
    }

    if(showDescription && widgetProps.description && resultValue){
        return (
            <div>
                {widgetProps.description}: <span style={{fontWeight: 600}}>{resultValue ?? ""} {resultValue && widgetProps?.['x-unit'] || ""}</span>
            </div>
        )
    }

    return `${resultValue ?? ""} ${resultValue && widgetProps?.['x-unit'] || ""}`
}

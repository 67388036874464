/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
/**
 * components
 */
import EditableStringWidget from "./components/Editable";
/**
 * enums
 */
import ResultKeysEnum from "../../../../enums/api/ResultKeys";
import getWidget from "../../../../utils/getWidget";
import {ValidateResult} from "../../../../utils/validateResult";

export default function WidgetString({ isFocused, result, widget, getError = () => {}, field, editable, onEdit, showDescription, }){
    const [validationErrorMessage, setValidationErrorMessage] = useState('')

    const widgetProps = useMemo(() => {
        if(!widget){
            return null;
        }

        return field ? widget.result.schema.properties[field] : widget.result.schema;
    }, [field, widget])
    const resultValue = useMemo(() => {
        if(!result?.measures || !widgetProps){
            return null
        }

        const newResultValue = field ? result.measures?.[field] : result.measures;

        return widgetProps.type.includes(typeof newResultValue) ? newResultValue : null
    }, [widgetProps, result?.measures])

    const validateResultHandler = (resultData) => {
        const {valid, message} = ValidateResult.validMeasures(resultData, widget, field)

        setValidationErrorMessage(!valid && message ? message : "")
        getError(!valid && message)
    }


    if(!result){
        return null;
    }

    if(editable){
        return (
            <EditableStringWidget widget={widgetProps}
                                  result={result}
                                  field={field}
                                  isFocused={isFocused}
                                  onEdit={onEdit}
                                  resultValue={resultValue}
                                  validationErrorMessage={validationErrorMessage}
                                  onBlur={validateResultHandler}/>
        )
    }

    if(showDescription && widgetProps?.description && resultValue){
        return (
            <div>
                {widgetProps.description}: <span style={{fontWeight: 600}}>{resultValue || ""} {resultValue && widgetProps?.['x-unit'] || ""}</span>
            </div>
        )
    }

    return `${resultValue || ""} ${resultValue && widgetProps?.['x-unit'] || ""}`
}

export default function matchTheSameMembers(includedMembers = []){
    if(!includedMembers.length){
        return []
    }

    const excludedMembers = includedMembers.reduce(( excludedMembers, currentMembers ) => {
        if(!excludedMembers?.length){
            return currentMembers
        }

        return currentMembers.reduce((newExcludedMembers, currentMember) => {
            const findMember = excludedMembers.find( currentMemberInExcludedMember => {
                return currentMemberInExcludedMember.id === currentMember.id
            })

            if(findMember){
                return [...newExcludedMembers, findMember]
            }

            return newExcludedMembers;
        }, [])
    }, [])


    return excludedMembers
}

import ColumnModel from "./column.model";
import RowModel from "./row.model";
import ResultModel from "../../medicalCard/result.model";

export default class ContentModel {
    constructor(data = {}) {
        this.columns = data.columns ? data.columns.map(column => new ColumnModel(column)) : [];
        this.rows = data.rows ?
            this.sortRows(
                data.rows
                    .map(rows => {
                        return rows
                            .map(row => new RowModel(row))
                            .sort((a, b) =>  a.column - b.column)
                    })
            ) : [];
        this.include = data.include;
    }

    sortRows( initialRows ){
        try {
            const rows = initialRows.map( row => row.sort((a,b) => a.column - b.column))

            const sortHelper = (key, rowIndex) => {
                let newArray = !rowIndex ? rows : sortRowsArray.slice(keyOrdinalWithIndex[key].startIndex, keyOrdinalWithIndex[key].endIndex + 1)
                newArray = newArray.sort((a, b) => a[rowIndex]?.ordinal - b[rowIndex]?.ordinal)

                if(!rowIndex){
                    sortRowsArray = newArray
                }

                if(rowIndex){
                    sortRowsArray.splice(keyOrdinalWithIndex[key].startIndex, keyOrdinalWithIndex[key].endIndex  - keyOrdinalWithIndex[key].startIndex + 1, ...newArray);
                }

                sortRowsArray.forEach((result, index) => {
                    if(!result[rowIndex]){
                        return;
                    }

                    if(!keyOrdinalWithIndex[result[rowIndex].ordinal]){
                        keyOrdinalWithIndex[result[rowIndex].ordinal] = {}
                    }

                    if(isNaN(keyOrdinalWithIndex[result[rowIndex].ordinal].startIndex)){
                        keyOrdinalWithIndex[result[rowIndex].ordinal].startIndex = index
                    }

                    keyOrdinalWithIndex[result[rowIndex].ordinal].endIndex = index
                })
            }

            let sortRowsArray = []
            let keyOrdinalWithIndex = {
                "start": true,
            };

            let biggestCountOfRows = rows.reduce((biggestCountOfRows, row) => {
                if(row.length > biggestCountOfRows){
                    return row.length
                }

                return biggestCountOfRows
            }, 0)


            for (let i = 0; i < biggestCountOfRows; i++){
                Object.keys(keyOrdinalWithIndex).forEach( key => {
                    sortHelper(key, i)
                })
            }


            return sortRowsArray
        }
        catch (e){
            console.log(e)
        }
    }
}

import {lazy} from "react";
import {Route} from "react-router-dom";
import {
    HOME_PAGE_URI,
    MEDICAL_CARDS_LIST_PAGE_URI,
    MEDICAL_CARDS_PAGE_URI,
    SETTINGS_CATALOG_EDIT_PAGE_URI,
    SETTINGS_CONTENT_PAGE_URI,
    SETTINGS_FODLERS_CREATE_PAGE_URI,
    SETTINGS_FODLERS_EDIT_PAGE_URI,
    SETTINGS_FODLERS_PAGE_URI,
    SETTINGS_WIDGET_CREATE_PAGE_URI,
    SETTINGS_WIDGET_EDIT_PAGE_URI,
    SETTINGS_SECURITY_CREATE_PAGE_URI,
    SETTINGS_WIDGET_PAGE_URI,
    SETTINGS_SECURITY_PAGE_URI,
    SETTINGS_SECURITY_EDIT_PAGE_URI,
    NOTIFICATIONS_PAGE_URI,
    SETTINGS_DOCUMENTATION_USER_AGREEMENT_PAGE_URI,
    SETTINGS_AUTH_DOCUMENTATION_USER_AGREEMENT_PAGE_URI,
    SETTINGS_AUTH_DOCUMENTATION_PERSONAL_INFO_PAGE_URI,
    SETTINGS_DELETE_ALL_DATA_PAGE_URI,
    SETTINGS_DOCUMENTATION_PERSONAL_INFO_PAGE_URI,
    VIEWER_EKG_PAGE_URI,
    MEDICAL_RECORD_PAGE_URI,
    MEDICAL_NEW_RECORD_PAGE_URI, MEDICAL_CARDS_SHARE_TOKEN_URI, AUTH_METHOD_PAGE_URI
} from "../../../constants/uri.constant";
import RolesEnums from "../../../enums/api/Roles";
import AuthRoute from "../Permission/AuthRoute";

export const webRoutes = (roles = []) => {
    const rolesId = roles.map(({id})  => id);
    let routes = [
        {
            layout: Route,
            path: AUTH_METHOD_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Auth'))
        },
        {
            layout: Route,
            path: SETTINGS_DOCUMENTATION_USER_AGREEMENT_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Documentation/File_1'))
        },
        {
            layout: Route,
            path: SETTINGS_DOCUMENTATION_PERSONAL_INFO_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Documentation/File_3'))
        },
        {
            layout: Route,
            path: SETTINGS_AUTH_DOCUMENTATION_USER_AGREEMENT_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Documentation/File_1'))
        },
        {
            layout: Route,
            path: SETTINGS_AUTH_DOCUMENTATION_PERSONAL_INFO_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Documentation/File_3'))
        },
        {
            layout: AuthRoute,
            path: SETTINGS_DELETE_ALL_DATA_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Account/remove'))
        },
        {
            layout: Route,
            path: VIEWER_EKG_PAGE_URI,
            hideLayout: true,
            component: lazy(() => import('../../../pages/web/pages/EKG'))
        },
        {
            layout: Route,
            path: '/viewer/svs',
            hideLayout: true,
            component: lazy(() => import('../../../pages/web/pages/Viewer/OpenSeadragon'))
        },
        {
            layout: AuthRoute,
            path: MEDICAL_CARDS_SHARE_TOKEN_URI,
            component: lazy(() => import('../../../pages/web/pages/ShareCard'))
        },
        {
            layout: AuthRoute,
            path: MEDICAL_CARDS_SHARE_TOKEN_URI,
            component: lazy(() => import('../../../pages/web/pages/ShareCard'))
        },
        {
            layout: AuthRoute,
            hideLayout: true,
            path: '/cor-galaxy/happy-new-year-2024',
            component: lazy(() => import('../../../pages/web/pages/ViewerNY/OpenSeadragon'))
        },
        {
            layout: AuthRoute,
            path: HOME_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Home'))
        },
        {
            layout: AuthRoute,
            path: NOTIFICATIONS_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/Notifications'))
        },
        {
            layout: Route,
            path: "/ekg",
            component: lazy(() => import('../../../pages/web/pages/EKG'))
        },
        {
            layout: AuthRoute,
            path: MEDICAL_CARDS_LIST_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/MedicalCardList'))
        },
        {
            layout: AuthRoute,
            path: MEDICAL_CARDS_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/MedicalCard'))
        },
        {
            layout: AuthRoute,
            path: MEDICAL_RECORD_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/MedicalCardRecord'))
        },
        {
            layout: AuthRoute,
            path: MEDICAL_NEW_RECORD_PAGE_URI,
            component: lazy(() => import('../../../pages/web/pages/MedicalCardRecord'))
        },
    ]

    if(true || rolesId.includes(RolesEnums.administrator) || rolesId.includes(RolesEnums.contentManager) || rolesId.includes(RolesEnums.contentReader)){
        routes = [
            ...routes,
            {
                layout: AuthRoute,
                path: SETTINGS_FODLERS_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Folders'))
            },

            {
                layout: AuthRoute,
                path: SETTINGS_FODLERS_EDIT_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Folders/EditFolder'))
            },
            {
                layout: AuthRoute,
                path:  SETTINGS_CATALOG_EDIT_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Catalog/EditCatalog'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_FODLERS_CREATE_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Folders/CreateFolder'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_WIDGET_CREATE_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Widget/CreateWidget'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_SECURITY_CREATE_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Security/CreateSecurity'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_WIDGET_EDIT_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Widget/EditWidget'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_SECURITY_EDIT_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Security/EditSecurity'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_CONTENT_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Catalog'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_WIDGET_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Widget'))
            },
            {
                layout: AuthRoute,
                path: SETTINGS_SECURITY_PAGE_URI,
                component: lazy(() => import('../../../pages/web/pages/Settings/Security'))
            },
        ]
    }

    routes = [
        ...routes,
        {
            layout: Route,
            path: "*",
            component: lazy(() => import('../../../pages/web/pages/NotFound'))
        }
    ]

    return routes.reduce((routes, route) => {
        if(route.hideLayout){
            return {
                ...routes,
                withoutLayout: [...routes.withoutLayout, route]
            }
        }

        return {
            ...routes,
            withLayout: [...routes.withLayout, route]
        }
    }, {withLayout: [], withoutLayout: []})
}

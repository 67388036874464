import AuthMethodEnum from "../enums/AuthMethod";

export const authDomain = process.env.REACT_APP_COR_IDENTITY_AUTH_DOMAIN

export const authConfig = {
    authorityDomain: authDomain,
    redirectUri: `${window.location.origin}/auth/${AuthMethodEnum.corIdentity}`,
    signUpSignIn: authDomain,
    resetPassword: `${authDomain}/static/forgot-password.html`,
};


// AUTH
const AUTH = `/auth`;
export const AUTH_PAGE_REGISTRATION_URI = `${AUTH}/registration`;
export const AUTH_PAGE_LOGIN_URI = `${AUTH}/login`;
export const AUTH_PAGE_FORGOT_PASSWORD_URI = `${AUTH}/forgot-password`;
export const AUTH_PAGE_LOGOUT_URI = `${AUTH}/logout`;
export const AUTH_PAGE_CONFIRM_EMAIL_URI = `${AUTH}/confirm-email/:token`;
export const AUTH_PAGE_SUSPICIOUS_ACTIVITY_URI = `${AUTH}/suspicious-activity/:userId/:token`;

//SETTINGS
const SETTINGS_PAGE_URI = `/settings`;
export const SETTINGS_FODLERS_PAGE_URI = `${SETTINGS_PAGE_URI}/folders`;
export const SETTINGS_WIDGET_PAGE_URI = `${SETTINGS_PAGE_URI}/widgets`;
export const SETTINGS_SECURITY_PAGE_URI = `${SETTINGS_PAGE_URI}/security`;
export const SETTINGS_FODLERS_EDIT_PAGE_URI = `${SETTINGS_PAGE_URI}/folders/edit/:folderId`;
export const SETTINGS_WIDGET_CREATE_PAGE_URI = `${SETTINGS_PAGE_URI}/widgets/create`;
export const SETTINGS_WIDGET_CREATE_DUPLICATE_MAKE_PAGE_URI = widgetId =>`${SETTINGS_PAGE_URI}/widgets/create?duplicateId=${widgetId}`;
export const SETTINGS_WIDGET_EDIT_PAGE_URI = `${SETTINGS_PAGE_URI}/widget/edit/:widgetId`;
export const SETTINGS_WIDGET_EDIT_MAKE_PAGE_URI = widgetId => `${SETTINGS_PAGE_URI}/widget/edit/${widgetId}`;
export const SETTINGS_SECURITY_EDIT_PAGE_URI = `${SETTINGS_PAGE_URI}/security/edit/:securityId`;
export const SETTINGS_SECURITY_EDIT_MAKE_PAGE_URI = securityId => `${SETTINGS_PAGE_URI}/security/edit/${securityId}`;
export const SETTINGS_SECURITY_CREATE_PAGE_URI = `${SETTINGS_PAGE_URI}/security/create`;
export const SETTINGS_FODLERS_CREATE_PAGE_URI = `${SETTINGS_PAGE_URI}/folders/create`;
export const SETTINGS_FODLERS_EDIT_MAKE_PAGE_URI = folderId => `${SETTINGS_PAGE_URI}/folders/edit/${folderId}`;
export const SETTINGS_CONTENT_PAGE_URI = `${SETTINGS_PAGE_URI}/content`;

export const SETTINGS_CATALOG_EDIT_PAGE_URI = `${SETTINGS_PAGE_URI}/catalog/edit/:catalogId`;
export const SETTINGS_CATALOG_EDIT_MAKE_PAGE_URI = catalogId => `${SETTINGS_PAGE_URI}/catalog/edit/${catalogId}`;


//HOME
export const HOME_PAGE_URI = `/`;
export const NOTIFICATIONS_PAGE_URI = `/notifications`;
export const SETTINGS_DOCUMENTATION_PERSONAL_INFO_PAGE_URI = `/documentation/personal-info`;
export const SETTINGS_DOCUMENTATION_USER_AGREEMENT_PAGE_URI = `/documentation/user-agreement`;
export const SETTINGS_AUTH_DOCUMENTATION_PERSONAL_INFO_PAGE_URI = `/personal-info`
export const SETTINGS_DELETE_ALL_DATA_PAGE_URI = `/account/remove`
export const SETTINGS_AUTH_DOCUMENTATION_USER_AGREEMENT_PAGE_URI = `/user-agreement`

//VIEWER
export const VIEWER_PAGE_URI = `/viewer`;
export const VIEWER_EKG_PAGE_URI = `${VIEWER_PAGE_URI}/ekg`;



//MEDICAL CARDS
export const MEDICAL_CARDS_LIST_PAGE_URI = `/cards/:folderId`;
export const MEDICAL_CARDS_LIST_MAKE_PAGE_URI = folderId => `/cards/${folderId}`;
export const MEDICAL_CARDS_PAGE_URI = `/card/:cardId/:folderId?`;
export const MEDICAL_CARDS_MAKE_PAGE_URI = (cardId, folderId = "") => `/card/${cardId}/${folderId}`;


//MEDICAL CARDS SHARE
export const MEDICAL_CARDS_SHARE_TOKEN_URI = `/cards/share`;


//RECORD
export const MEDICAL_RECORD_PAGE_URI = `/record/:recordId/:folderId?`;
export const MEDICAL_RECORD_MAKE_PAGE_URI = (recordId, folderId) => `/record/${recordId}${folderId ? `/${folderId}` : ""}`;
export const MEDICAL_NEW_RECORD_PAGE_URI = `/new/record/:cardId/:folderId/:templateRecordId`;
export const MEDICAL_NEW_RECORD_MAKE_PAGE_URI = (cardId, folderId, templateRecordId) => `/new/record/${cardId}/${folderId}/${templateRecordId}`;

//AUTH
export const AUTH_PAGE_URI = `/auth`;
export const AUTH_METHOD_PAGE_URI = `/auth/:authMethod`;

import StorageService from "../services/storage.service";
import AuthMethodEnum from "../enums/AuthMethod";

export default class Auth {
    static getAuthMethod () {
        const allowAuth = process.env.REACT_APP_AUTH_MODULE.split(',');
        const [_, partOfUrl, authMethod] = window.location.pathname.split("/");
        const isAuthUrl = partOfUrl === "auth";

        if(isAuthUrl){
            return authMethod;
        }

        const activeAuthMethod = StorageService.activeAuthMethod;
        const queryAuth = new URLSearchParams(window.location.search).get('auth');
        const currentAuthMethod = allowAuth.includes(queryAuth?.toLowerCase()) ? queryAuth : allowAuth.includes(activeAuthMethod?.toLowerCase()) ? activeAuthMethod : null;

        if(!currentAuthMethod){
            console.warn(`${queryAuth || activeAuthMethod}: UNSUPPORTED AUTH METHOD`);
        }

        StorageService.activeAuthMethod = currentAuthMethod || allowAuth[0];

        return (currentAuthMethod || allowAuth[0]).toLowerCase();
    }
}


/**
 *service
 */
import axios from 'axios'
/**
 * const
 */
import {
    API_CREATE_WIDGETS_URL, API_DELETE_WIDGETS_URL,
    API_GET_WIDGETS_NEW_URL,
    API_LIST_WIDGETS_URL,
    API_UPDATE_WIDGETS_URL
} from "../constants/api.constant";
/**
 * utils
 */
import {QueryString} from "../utils/Querystring";
import WidgetModel from "../model/medicalCard/widget.model";

export default class WidgetService {
    static async listFrontend( params = {} ) {
        return await axios.get(API_GET_WIDGETS_NEW_URL, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .then(({widget}) => widget)
            .catch(e => Promise.reject(e))
    }

    static async list(params = {}) {
        const widgets = await axios.get(API_LIST_WIDGETS_URL, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        });

        return widgets.map(widget => new WidgetModel(widget));
    }

    static async get(widgetId) {
        return await axios.get(API_LIST_WIDGETS_URL, {
            params: {id: widgetId},
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
        .then((response) => {
            if (Array.isArray(response) && response.length > 0) {
                return response[0];
            }
            return response;
        })
        .catch(e => Promise.reject(e));
    }

    static async edit(data, params = {}) {
        return await axios.patch(API_UPDATE_WIDGETS_URL, data, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        });
    }

    static async create(data, params = {}) {
        return await axios.post(API_CREATE_WIDGETS_URL, data, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        });
    }

    static async delete(widgetId) {
        try {
            await axios.delete(API_DELETE_WIDGETS_URL, { params: { id: widgetId } });
            return true; // Return true to indicate successful deletion
        } catch (error) {
            console.error('NotFound deleting widget:', error);
            return false; // Return false to indicate deletion failure
        }
    }
}

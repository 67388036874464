import React, {useEffect, useRef, useState} from "react";
import styles from './index.module.scss'
import { validate as uuidValidate } from 'uuid';
import {useTranslation} from "react-i18next";

export default function SearchLineSearch({ onSearch, getScanResult, getSearchValue, showAcceptBtn = true }){
    const [searchValue, setSearchValue] = useState('');
    const inputRef = useRef();
    const {t} = useTranslation()

    const onSearchKeyPressHandler = async (e) => {
        if (e.which === 13) {
            await onSearch(searchValue)
            setSearchValue("")
        }
    }

    const onChange = (e) => {
        const value = e.target.value;
        setSearchValue(value)
        if(value.indexOf("COR_") === 0){
            getScanResult({
                id: value.split('_')[1],
                scan: true,
            })
            return;
        }

        if(getSearchValue){
            getSearchValue(value)
        }
    }

    const onSearchHandler = async (e) => {
        await onSearch(searchValue)
        setSearchValue("")
    }

    useEffect(() => {
        if(inputRef.current){
            inputRef.current.focus()
        }
    }, [])

    return (
        <>
            <input type="text" value={searchValue} ref={inputRef} onChange={onChange} onKeyUp={onSearchKeyPressHandler} placeholder={t("Search")} className={styles.search}/>
            {showAcceptBtn && searchValue && (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor: "pointer"}} onClick={onSearchHandler}>
                    <path d="M3.1579 10.75L7.17761 16.1628C7.8996 17.135 9.31312 17.2476 10.1799 16.402L20.8421 6" stroke="#323238" stroke-width="2" stroke-linecap="round"/>
                </svg>
            )}
        </>
    )
}

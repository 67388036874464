/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {DatePicker} from 'antd';
import dayjs from 'dayjs';
/**
 * styles
 */
import styles from './index.module.scss'
import FieldItem from "../../../../../Form/components/FieldItem";
import {useTranslation} from "react-i18next";
import DateTime from "../../../../../../utils/DateTime";


export default function EditableDateWidget({onEdit, field, widget, resultValue, onBlur, result}) {
    const {t} = useTranslation()
    const datePickerRef = useRef(null)
    const [resultData, setResultData] = useState(result)

    const onChangeHandler = (date, str) => {
        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: (date ? DateTime.dateToYmd(new Date(date), "-") : null),
        } : (date ? DateTime.dateToYmd(new Date(date), "-") : null)

        const newResultData = {
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        }

        setResultData(newResultData)
        onEdit(newResultData)
    }


    useEffect(() => {
        setResultData(result)
    }, [result])


    return (
        <FieldItem label={widget.description || ""}  required={!widget.type.includes("null")} stylesWrapper={{marginBottom: 10}}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                <DatePicker
                    defaultValue={resultValue ? dayjs(new Date(resultValue)) : null}
                    ref={datePickerRef}
                    className={styles.wrapper__date}
                    style={{width: "100%"}}
                    format={['DD-MM-YYYY', 'DD.MM.YYYY', 'DD/MM/YYYY']}
                    showToday={false}
                    placeholder={t("Select date")}
                    onChange={onChangeHandler}/>
            </div>
        </FieldItem>
    )
}

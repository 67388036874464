import React from "react"
import CorIdentityAuth from "./CorIdentity";
import AzureAuth from "./Azure";
import {QueryString} from "../../utils/Querystring";
import { jwtDecode } from 'jwt-decode'
import Auth from "../../utils/Auth";
import AuthMethodEnum from "../../enums/AuthMethod"; // import dependency

export default function AuthModules({ children }){
    const currentAuthMethod = Auth.getAuthMethod()


    if(currentAuthMethod === AuthMethodEnum.corIdentity){
        return <CorIdentityAuth>{children}</CorIdentityAuth>
    }

    if(currentAuthMethod === AuthMethodEnum.azure){
        return <AzureAuth>{children}</AzureAuth>
    }

    return null
}


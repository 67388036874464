import DimensionModel from "../../dimension.model";

export default class RowModel {
    constructor(data = {}) {
        this.id = data.id;
        this.name = data.name;
        this.icon = data.icon;
        this.column = data.column;
        this.is_leaf = data.is_leaf;
        this.ordinal = data.ordinal;
        this.dimension = data.dimension ? new DimensionModel(data.dimension) : null;

    }
}

/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
/**
 * styles
 */
import styles from './index.module.scss'
import GetIcon from "../../../../../GetIcon";
import generateIconUrl from "../../../../../../utils/generateIconUrl";

export default function SearchLineMembers({selectedMembers, setSelectedMembers, setOpenSelectMember,getMembersData, openSelectMember}){
    const formattedSelectedMembers = useMemo(() => {
        return selectedMembers.reduce((newSelectedMembers, members) => {
            if(!newSelectedMembers[members.column]){
                newSelectedMembers[members.column] = []
            }

            newSelectedMembers[members.column] = [...newSelectedMembers[members.column], members]

            return newSelectedMembers
        }, {})
    }, [selectedMembers])

    const removeSelectedMember = (memberId) => {
        setSelectedMembers(selectedMembers.filter( member => member.id !== memberId))
        setOpenSelectMember(true)
        getMembersData({members: selectedMembers.filter( member => member.id !== memberId).filter(({columnOptions}) => !columnOptions.is_multiple).map(({id}) => id)})
    }

    const removeGroupSelectedMember = (members) => {
        const membersId = members.map(({id}) => id)
        setSelectedMembers(selectedMembers.filter( member => !membersId.includes(member.id)))
        setOpenSelectMember(true)
        getMembersData({members: selectedMembers.filter( member => !membersId.includes(member.id)).filter(({columnOptions}) => !columnOptions.is_multiple).map(({id}) => id)})
    }

    if(!selectedMembers.length){
        return null
    }

    return (
        <div className={styles.wrapper}>
            {Object.keys(formattedSelectedMembers).map( key => (
                <div key={key} className={styles.wrapper__item}>
                    {formattedSelectedMembers[key].map((member) => (
                        <div key={member.id} className={styles.wrapper__member}>
                            {member.icon && (
                                <div className={styles.wrapper__member_icon}>
                                    {member.icon && (
                                        <GetIcon backendUrl={generateIconUrl(member.icon)}/>
                                    )}
                                </div>
                            )}
                            {member.name}
                            {!openSelectMember && (
                                <div style={{width: 24, height: 24}}>
                                    <svg className={formattedSelectedMembers[key].length <= 1 && styles.notHide} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeSelectedMember(member.id)}>
                                        <path d="M7.625 16.375L16.3754 7.62455" stroke="#1084FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.625 7.625L16.3754 16.3754" stroke="#1084FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            )}
                        </div>
                    ))}
                    {!openSelectMember && formattedSelectedMembers[key].length > 1 && (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeGroupSelectedMember(formattedSelectedMembers[key])}>
                            <path d="M7.625 16.375L16.3754 7.62455" stroke="#1084FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.625 7.625L16.3754 16.3754" stroke="#1084FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    )}
                </div>

            ))}
        </div>
    )
}

/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
/**
 * enums
 */
import ResultKeysEnum from "../../../../enums/api/ResultKeys";
import getWidget from "../../../../utils/getWidget";


export default function WidgetMask({ result, showDescription, widget, extractValuesFromMask}){
    const matchValueInMask = extractValuesFromMask(widget.result.widget.mask)
    const completeMask = useMemo(() => {
        if(!result.measures){
            return ""
        }

        let fullCompleteMask = widget.result.widget.mask
        let countOfNoValue = 0;
        matchValueInMask.forEach((key) => {
            countOfNoValue += !Object.keys(result.measures).includes(key) ? 1 : 0;
            fullCompleteMask =  fullCompleteMask.replace(`{${key}}`, result.measures[key] ?? "")
        })

        return countOfNoValue === matchValueInMask.length ? "" : fullCompleteMask;
    }, [matchValueInMask])

    if(!result || !completeMask){
        return null;
    }

    if(showDescription && widget.result?.widget?.description){
        return (
            <div>
                {widget.result.widget.description}: <span style={{fontWeight: 600}}>{completeMask}</span>
            </div>
        )
    }

    return completeMask
}
